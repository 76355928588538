import React, {useEffect, useRef} from "react";
import {IMapElement, PolygonCoordinates} from "../../../../Types/Common";
import {
    Marker,

    MarkerF,

    PolygonF, PolylineF,
    useGoogleMap
} from "@react-google-maps/api";
import {DefaultMarkerEditIcon, DefaultMarkerIcon} from "../../../../constants";
import {ActiveMapElement, EditMapElement} from "../../../../Types/atoms";
import {useRecoilState} from "recoil";

const MapFeature: React.FC<IMapElement> = ({...mapElement}) => {
    const refPolygon = useRef<google.maps.Polygon>();
    //const map = useGoogleMap()
    const [activeMarker, setActiveMarker] = useRecoilState(ActiveMapElement);
    const [editElement, setEditElement] = useRecoilState(EditMapElement);

    const [polygonCoords, setPolygonCoords] = React.useState<google.maps.MVCArray<google.maps.LatLng> | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | google.maps.LatLng[] | google.maps.LatLng[][] | google.maps.LatLngLiteral[] | google.maps.LatLngLiteral[][]>([]);
    const [polygonCenter, setPolygonCenter] = React.useState<google.maps.LatLngLiteral>({lat: 0, lng: 0});


    useEffect(() => {
        if (!mapElement.polygon) return;
        const coords = convertToLatLngLiteral(mapElement.polygon.coordinates)
//        //console.log("useEffect mapElement.polygon", coords)
        setPolygonCoords(coords);
        const bounds = new google.maps.LatLngBounds();

        coords.map(coord => {
            bounds.extend(coord);
        })
        setPolygonCenter({lat: mapElement.center.latitude, lng: mapElement.center.longitude})
        //console.log(mapElement.center)

    }, [mapElement.polygon])


    const convertToLatLngLiteral = (coords: PolygonCoordinates) => {
        return coords[0].map(coord => {
            return {
                lat: coord[1],
                lng: coord[0]
            };
        });
    };
    const infoWindowStyle = {
        background: `white`,
        border: `1px solid ##CCC`,
        padding: 0,
    }

    const polygonLabelUrl = "data:image/svg+xml;charset=UTF-8;base64," + btoa(unescape(encodeURIComponent(mapElement.name)));

    const onDragEnd = (e: google.maps.MapMouseEvent) => {
        //console.log("onDragEnd", e)
        //console.log(e.latLng?.lat())
        //console.log(refPolygon.current?.getPath())

    }
    const onLoad = (item: google.maps.Polygon) => {
        //console.log("onLoadfeature", item)
        refPolygon.current = item;

//        //console.log(refPolygon.current?.getPath())
    }

    useEffect(() => {
        if (refPolygon.current === undefined) return;
        //console.log("useEffect mapElement.editable", mapElement.name, mapElement.editable)
        //console.log("refPolygon", refPolygon.current)
        const current_path = refPolygon.current?.getPath()
        if (!current_path) return;

        if (mapElement.editable === true) {
            console.log("edit started")
            //console.log(JSON.stringify(refPolygon.current?.getPath()?.getArray()))
        }
        if (mapElement.editable === false) {
            console.log("edit ended")

            refPolygon.current.setPath(current_path)
            console.log(JSON.stringify(refPolygon.current?.getPath()?.getArray()))
        }
    }, [mapElement.editable])
    const getColor = () => {
        let color= mapElement.color;
        if (mapElement.id=== activeMarker?.id) {
            color="#797979";
        }
        return color
    }
    return (
        <>
            {mapElement.point ?

                <Marker onClick={()=>setActiveMarker(mapElement)}  key={`marker-${mapElement.id}`}
                    //label={mapElement.name}
                        label={{className: `map-label map-color-${mapElement.color_display} `, text: `${mapElement.name}`}}
                        position={{lat: mapElement.point.coordinates[1], lng: mapElement.point.coordinates[0]}}
                        options={{
                            draggable: editElement?.id === mapElement.id,
                            anchorPoint: new google.maps.Point(50, 20),
                            crossOnDrag: true,

                            icon: mapElement.icon ?
                                mapElement.icon :

                                {...DefaultMarkerIcon,rotation: 90,fillColor:mapElement?.editable?"#c4c4c4":"#ff0000",strokeColor: mapElement.color},


                        }}/>
                : null
            }
            {mapElement.polygon ?
                <>
                    <PolygonF onClick={()=>setActiveMarker(mapElement)}
                             key={`polygon-${mapElement.id}`}
                             paths={convertToLatLngLiteral(mapElement.polygon.coordinates)}
                             onDragEnd={onDragEnd}
                             onLoad={onLoad}
                             options={{
                                 editable: editElement?.id === mapElement.id,
                                 draggable: editElement?.id === mapElement.id,
                                 fillColor: mapElement.color,
                                 strokeColor: getColor(),
                                 fillOpacity: 0.4,
                                 strokeOpacity: 0.8,
                                 strokeWeight: 3
                             }}/>

                    <MarkerF key={`polygon-1-${mapElement.id}`}
                            position={polygonCenter}
                            label={{
                                className: `map-label map-color-${mapElement.color_display}`,
                                text: mapElement.name
                            }}

                            options={{}} icon={polygonLabelUrl}></MarkerF>
                    {/*<OverlayView*/}
                    {/*    position={polygonCenter}*/}
                    {/*    mapPaneName={OVERLAY_MOUSE_TARGET}*/}
                    {/*>*/}
                    {/*    <InfoWindow position={polygonCenter}>*/}
                    {/*        <div style={infoWindowStyle}>*/}
                    {/*            <p>{mapElement.name}</p>*/}
                    {/*        </div>*/}
                    {/*    </InfoWindow>*/}
                    {/*</OverlayView>*/}
                </>

                : null}

            {mapElement.line ?
                <>
                    <PolylineF onClick={()=>setActiveMarker(mapElement)} key={`line-${mapElement.id}`}
                              path={mapElement.line.coordinates.map(coord => new google.maps.LatLng(coord[1], coord[0]))}
                              options={{
                                  strokeColor: getColor(),
                                  editable: editElement?.id === mapElement.id,
                                  draggable: editElement?.id === mapElement.id,}}/>
                    <MarkerF key={`polygon-1-${mapElement.id}`}
                            position={polygonCenter}
                            label={{
                                className: `map-label map-color-${mapElement.color_display}`,
                                text: mapElement.name
                            }}

                            options={{}} icon={polygonLabelUrl}></MarkerF>
                </>
                : null}

        </>
    )

}
export default MapFeature;