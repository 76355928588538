import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCurrentBrowserFingerPrint} from "@rajesh896/broprint.js";
import {useRecoilState} from "recoil";
import {AtomAditionalMarkers, AtomMyLocation, VisitorId} from "../Types/atoms";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {defaultLibraries, GoogleApiKey, WEBSOCKET_URL} from "../constants";
import {useSharedWebSocket} from "./WebsocketProvider";
import {toast, ToastOptions} from "react-toastify";
import {EWsMsgType, WsMessage, WsMessageUserLocation} from "../Types/Ws";
import {IMapElement} from "../Types/Common";
import IconFirefighter from "../assets/firefighter-svgrepo-com.svg";
import {useJsApiLoader} from "@react-google-maps/api";
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/react";


export interface LocationState {
    accuracy: number;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    latitude: number;
    longitude: number;
    speed: number | null;
    timestamp: number;
}

const LocationContext = createContext<LocationState | null>(null);

export const useLocation = (): LocationState | null => {
    return useContext(LocationContext);
};

interface LocationProviderProps {
    children: React.ReactNode;
}

export const LocationProvider = ({children}: LocationProviderProps) => {
    const [location, setLocation] = useRecoilState<LocationState | null>(AtomMyLocation);
    const [visitorId, setVisitorId] = useRecoilState(VisitorId);
    const {sendMessage, lastMessage, readyState} = useSharedWebSocket();
    const toastOptions: ToastOptions = {autoClose: 30000, toastId: "geolocation_error", theme: "colored"}
    const {t,i18n} = useTranslation();

    let watchId: number;
    const [additionalMarkers, setAdditionalMarkers] = useRecoilState(AtomAditionalMarkers);
    const googleMapsLoader = useJsApiLoader({
        id: `map-${i18n.language}`,
        language: i18n.language,
        region: i18n.language,
        googleMapsApiKey: GoogleApiKey,
        libraries: defaultLibraries,
        //libraries:["drawing"]
    });

    const updateOrAddItem = (itemToUpdate: WsMessageUserLocation) => {
        const currentTime = new Date().getTime(); // Current time in milliseconds
        if (!googleMapsLoader.isLoaded) return;

        const marker: IMapElement = {
            id: itemToUpdate.browser_id,
            group: "additionalMarkers",
            name: `${itemToUpdate.user.email} ${itemToUpdate.browser_id}`,
            center: {
                latitude: itemToUpdate.location.coordinates[1],
                longitude: itemToUpdate.location.coordinates[0],
            },
            point: {
                type: "Point",
                coordinates: [itemToUpdate.location.coordinates[0], itemToUpdate.location.coordinates[1]]
            },
            bounds: [
                itemToUpdate.location.coordinates[1],
                itemToUpdate.location.coordinates[0],
                itemToUpdate.location.coordinates[1],
                itemToUpdate.location.coordinates[0]]
            ,
            color: "blue",
            color_display: "blue",
            icon: {
                scaledSize: new google.maps.Size(30, 30),
                url: IconFirefighter,
                labelOrigin: new google.maps.Point(15, 40),
            },

        }
        setAdditionalMarkers((prevItems) => {
            const itemIndex = prevItems.findIndex((item) => item.id === marker.id);

            if (itemIndex > -1) {
                // Update the existing item
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = marker;
                return updatedItems;
            } else {
                // Add the new item
                return [...prevItems, marker];
            }
        });
        //removeOldItems();

    };
    useEffect(() => {
        if (lastMessage === null) return;
        const data: WsMessage = JSON.parse(lastMessage?.data)

        if (data.msg_type === EWsMsgType.USER_LOCATION) {
            //console.log("map_element", data)
            updateOrAddItem(data.data as WsMessageUserLocation)

        }
    }, [lastMessage]);


    useEffect(() => {
        //console.log("readyState", readyState, "visitorId", visitorId, "location", location)
        if (!visitorId) return
        if (readyState != ReadyState.OPEN) return
        if (location) {
            //console.log("sending location", location)
            sendMessage(JSON.stringify({type: "user_location", browser_id: visitorId, data: location}))
        }
    }, [location, readyState])

    useEffect(() => {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
            // fingerprint is your unique browser id.
            // This is well tested
            if (!visitorId) {
                setVisitorId(fingerprint)
            } else {
                console.log("visitorId", visitorId, "fingerprint", fingerprint)
            }
            // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.
        })
        checkWathcId()


    }, []);

    const checkWathcId = () => {
        console.log("checkWathcId", watchId)
        if (watchId) return
        if ("geolocation" in navigator) {
            watchId = navigator.geolocation.watchPosition(
                (position) => {
                    //const location_data: LocationState = {...position.coords,timestamp:position.timestamp}
                    const location_data: LocationState = {
                        accuracy: position.coords.accuracy,
                        altitude: position.coords.altitude,
                        altitudeAccuracy: position.coords.altitudeAccuracy,
                        heading: position.coords.heading,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        speed: position.coords.speed,
                        timestamp: position.timestamp

                    }

                    if (!location) {
                        setLocation(location_data);
                    }
                    if (location_data.latitude === location?.latitude && location_data.longitude === location?.longitude) {
                        console.log("same location")
                        return
                    }
                    setLocation(location_data);


                },
                (error) => {
                    console.error('Error getting location:', error);
                    Sentry.captureException(error);

                    if (error.code == 1 && window.djiBridge===undefined) toast.error(t("geolocation_error"), toastOptions);

                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 10000,
                }
            );

            // Cleanup: Stop watching the user's location when component is unmounted
            return () => navigator.geolocation.clearWatch(watchId);
        } else {
            toast.error("Geolocation not available",)
        }
    }
    return (
        <LocationContext.Provider value={location}>
            {children}
        </LocationContext.Provider>
    );
};