import React, {memo, useEffect, useState} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Badge, Alert,
} from "reactstrap";

//Import Images
import {useNavigate} from "react-router-dom";
import {IDrone} from "../../Types/Dron";
import EditDroneName from "../../partials/EditDroneName";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import dayjs from "../../helpers/dayjs";
import classnames from "classnames";

interface CardDroneProps {
    droneData: IDrone;
}

const CardDrone: React.FC<CardDroneProps> = (props, context) => {

    const [settingsMenu, setSettingsMenu] = useState<any>(false);
    const navigate = useNavigate();
    const droneData = props.droneData;
    const {t, i18n} = useTranslation();

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    {!droneData.active && (
                        <Alert className={'small,'} color='warning'>
                            {t("Dron is active on another account")}: {droneData.active_company_name}
                        </Alert>
                    )}
                    <Card outline={!droneData.active} color={!droneData.active ? "warning":""} className={classnames({border:true,inactive: !droneData.active})}>

                        <CardBody >
                            <Row>

                                <Col lg={4} >
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <div
                                                className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                              className={"mdi mdi-google-controller font-size-24"}
                          ></i>
                        </span>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <h5 className="mb-1">
                                                    <EditDroneName {...droneData.parent} />

                                                </h5>
                                                <p className="mb-0">
                                                    {t("Serial number")}: {droneData.parent.sn}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={8} className="align-self-center">
                                    <div className="text-lg-center mt-4 mt-lg-0">
                                        <Row>
                                            <Col xs={4}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        Status
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {" "}
                                                        {droneData.parent.is_online ? (
                                                            <Badge pill color="success">
                                                                {t("Online")}
                                                            </Badge>
                                                        ) : (
                                                            <Badge pill color="danger">

                                                                {t("Offline")}

                                                            </Badge>
                                                        )}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Last online")}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {dayjs(droneData?.parent?.online)?.fromNow() ??
                                                            " -- "}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs={4}></Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>

                        <CardBody>
                            <Row>
                                <Col lg={4}>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <div
                                                className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"mdi mdi-quadcopter font-size-24"}></i>
                        </span>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <h5 className="mb-1">{droneData.full_name}</h5>
                                                <p className="mb-0">{t("Serial number")}: {droneData.sn}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={8} className="align-self-center">
                                    <div className="text-lg-center mt-4 mt-lg-0">
                                        <Row>
                                            <Col xs={4}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t('Status')}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {droneData.is_online ? (
                                                            <Badge pill color="success">
                                                                {t("Online")}
                                                            </Badge>
                                                        ) : (
                                                            <Badge pill color="danger">
                                                                {t("Offline")}
                                                            </Badge>
                                                        )}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Last online")}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {dayjs(droneData?.online)?.fromNow() ?? " -- "}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <div className="float-end">
                                                    <Button
                                                        tag="button"
                                                        className="btn btn-primary"
                                                        onClick={() => navigate(`/drone/${droneData.id}`)}
                                                    >
                                                        <i className="bx bxs-cog align-middle me-1"/> {t("Open Details")}

                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default React.memo(CardDrone, isEqual);
