import * as Sentry from "@sentry/react";
import PageErrorBoundary from "../PageErrorBoundary/PageErrorBoundary";
import React, {memo, useEffect, useState} from "react";
import {Card, CardBody, Container} from "reactstrap";
import Breadcrumb from "../../Components/Common/Breadcrumb";

// import Component
import FileLeftBar from "./FileLeftBar";
import FileList from "./FileList";
import RecentFile from "./RecentFile";
import Storage from "./Storage";
import {listMediaApi} from "../../helpers/sarlink_backed";
import {IMedia, IMediaResponse} from "../../Types/Media";
import {Pagination, PaginationProps} from "antd";
import {toast} from "react-toastify";
import Spinners from "../../Components/Common/Spinner";
import isEqual from "react-fast-compare";

const Media: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(100);
    const [totalPages, setTotalPages] = useState<number | null>(0);
    const [mediaData, setMediaData] = useState<IMedia[]>([]);
    const [totalMedia, setTotalMedia] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const intervalCall = setInterval(() => {
            getMediaData();
        }, 30000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };

    }, []);
    //const series = [76]


    // const options = {
    //     chart: {
    //         height: 150,
    //         type: "radialBar",
    //         sparkline: {
    //             enabled: true,
    //         },
    //     },
    //     colors: ["#556ee6"],
    //     plotOptions: {
    //         radialBar: {
    //             startAngle: -90,
    //             endAngle: 90,
    //             track: {
    //                 background: "#e7e7e7",
    //                 strokeWidth: "97%",
    //                 margin: 5, // margin is in pixels
    //             },
    //
    //             hollow: {
    //                 size: "60%",
    //             },
    //
    //             dataLabels: {
    //                 name: {
    //                     show: false,
    //                 },
    //                 value: {
    //                     offsetY: -2,
    //                     fontSize: "16px",
    //                 },
    //             },
    //         },
    //     },
    //     grid: {
    //         padding: {
    //             top: -10,
    //         },
    //     },
    //     stroke: {
    //         dashArray: 3,
    //     },
    //     labels: ["Storage"],
    // }
    useEffect(() => {
        console.log("useEffect", currentPage, pageSize)
        getMediaData()
    }, [pageSize, currentPage]);

    const getMediaData = async () => {
        setIsLoading(true)

        await listMediaApi(currentPage, pageSize).then((response: IMediaResponse) => {
            setTotalPages(response.total_pages);
            setCurrentPage(response.current);
            setMediaData(response.results)
            setTotalMedia(response.count)

        }).catch((error:any) => {
            toast.error(error.message)

        }).finally(() => {
            setIsLoading(false)
        });

    }
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        console.log("onShowSizeChange", current, pageSize);

        setPageSize(pageSize)
    };
    const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log("onPageChange", page, pageSize);
        setCurrentPage(page)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Media" breadcrumbItem="Media Manager"/>
                    <div className="d-xl-flex">
                        <div className="w-100">
                            <div className="d-md-flex">
                                {/* FileRightBar  */}
                                {/*<FileLeftBar/>*/}
                                <div className="w-100">
                                    <Card>
                                        <CardBody>
                                            {isLoading && <Spinners setLoading={setIsLoading}/>}

                                            <FileList mediaData={mediaData}></FileList>
                                            <Pagination
                                                defaultCurrent={currentPage}
                                                total={totalMedia}
                                                onShowSizeChange={onShowSizeChange}
                                                onChange={onPageChange}
                                                pageSize={pageSize}
                                                showSizeChanger/>

                                            {/*<RecentFile/>*/}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        {/*<Storage options={options} series={series}></Storage>*/}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default memo(Sentry.withErrorBoundary(Media, {fallback: PageErrorBoundary}),isEqual);