import {atom} from 'recoil';
import {IJwt, IMapElement, IMapElementGroup, ISwitchCompany, IUserInfo, IWorkspace} from "./Common";
import {recoilPersist} from "recoil-persist";
import {getLocalAccessToken, getLocalRefreshToken} from "../helpers/api_helper";
import {LocationState} from "../Providers/LocationProvider";
import {EPhotoType, EStatusValue} from "../DjiBridge/enums";
import {MediaParam} from "../DjiBridge/DjiBridge";
import {IDrone} from "./Dron";
import {ILastBattery, ILastLocation, ILastPayload} from "./Dashboard";

export const UserInfo = atom<IUserInfo|null>({
    key: 'UserInfo', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});
export const SwitchCompany = atom<ISwitchCompany[]>({
    key: 'SwitchCompany', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});
export const JwtDecoded = atom<IJwt|null>({
    key: 'JwtDecoded', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export const AccessToken = atom({
    key: 'AccessToken', // unique ID (with respect to other atoms/selectors)
    default: getLocalAccessToken(), // default value (aka initial value)
});
export const RefreshToken = atom({
    key: 'RefreshToken', // unique ID (with respect to other atoms/selectors)
    default: getLocalRefreshToken(), // default value (aka initial value)
});

export const ActiveMapElement = atom<IMapElement|undefined>({
    key: 'ActiveMapElement', // unique ID (with respect to other atoms/selectors)
    default:undefined, // default value (aka initial value)
});
export const EditMapElement = atom<IMapElement|undefined>({
    key: 'EditMapElement', // unique ID (with respect to other atoms/selectors)
    default:undefined, // default value (aka initial value)
});

export const IsAuth = atom({
    key: 'isAuth', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
});
const { persistAtom } = recoilPersist()

export const AtomPlayingStreams = atom<string[]>({
    key: 'playingStreams', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],

});
export const AtomMapFitBounds = atom<google.maps.Map|undefined>({
    key: 'mapRef', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value)
});

export const AtomMapGroup = atom<IMapElementGroup|null>({
    key: 'mapGroup', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],

});
export const AtomWorkspace = atom<IWorkspace|null>({
    key: 'workspace', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],

});

export const VisitorId = atom<string|null>({
    key: 'VisitorId', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],

});

export const AtomDrones = atom<IDrone[]>({
    key: 'AtomDrones', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)

});

export const AtomMyLocation = atom<LocationState|null>({
    key: 'myLocation', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)

})

export const AtomDjiThingState= atom<EStatusValue>({
    key: 'djiThingState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})
export const AtomDjiApiState= atom<EStatusValue>({
    key: 'djiApiState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiLiveState= atom<EStatusValue>({
    key: 'djiLiveState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiWsState= atom<EStatusValue>({
    key: 'djiWsState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiMapState= atom<EStatusValue>({
    key: 'djiMapState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiTsaState= atom<EStatusValue>({
    key: 'djiTsaState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiMediaState= atom<EStatusValue>({
    key: 'djiMediaState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomDjiWaylineState= atom<EStatusValue>({
    key: 'djiWaylineState',
    default: EStatusValue.DISCONNECT,
    effects_UNSTABLE: [persistAtom],

})

export const AtomGatewayState= atom<boolean>({
    key: 'gatewayState',
    default: false,
})
export const AtomAircraftBindState= atom<boolean>({
    key: 'AircraftBindState',
    default: false,
})
export const AtomMediaParam= atom<MediaParam>({
    key: 'MediaParam',
    default: {
        autoUploadPhoto: true,
        autoUploadPhotoType: EPhotoType.Original,
        autoUploadVideo: true
    },
    effects_UNSTABLE: [persistAtom],

})


export const AtomAditionalMarkers = atom<IMapElement[]>({
    key: 'additionalMarkers', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)

});
