import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";
import {LOGO_BIG_PATH,LOGO_SMALL_PATH} from "../../constants";
import {withTranslation} from "react-i18next";

//import images

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">


          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={LOGO_SMALL_PATH} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={LOGO_BIG_PATH} alt="" height="39" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Sidebar);