import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import {navBarTitle} from "../../constants";

import {useRecoilValue} from "recoil";
import {AtomDrones} from "../../Types/atoms";
import CardDrone from "./card-drone";
import {IDrone, IDroneGroup} from "../../Types/Dron";

import EditDroneName from "../../partials/EditDroneName";
import {useTranslation} from "react-i18next";
import {groupByCompanyName} from "../../helpers";

const Dashboard = () => {
    const {t, i18n} = useTranslation();

    const drones = useRecoilValue(AtomDrones);
    console.log("DRONES here", drones);
    document.title = `${t("Dashboard")} | ${navBarTitle}`;


    const groupedData: IDroneGroup = groupByCompanyName(drones);
    const printWithHeader = (key: string, data: IDrone[]) => {
        return (
            <Card key={key}>
                <CardHeader
                    className="bg-transparent border-bottom text-uppercase">{key}</CardHeader>
                <CardBody>

                    {data.map((droneData: IDrone) => {
                        return (
                            <div
                                key={`${droneData?.id}-${droneData?.created}-${droneData?.device_info}-${droneData?.last_flight}-${droneData?.parent?.full_name}-${droneData?.modified}`}
                            >
                                <CardDrone
                                    key={`${droneData?.id}-${droneData?.created}-${droneData?.device_info}-${droneData?.last_flight}}-${droneData?.parent?.full_name}-${droneData?.modified}`}
                                    droneData={droneData}
                                />
                            </div>
                        );
                    })
                    }
                </CardBody>
            </Card>
        )
    };
    const printWoHeader = (key: string, data: IDrone[]) => {
        return (
            <>
                {data.map((droneData: IDrone) => {
                    return (
                        <div
                            key={`${droneData?.id}-${droneData?.created}-${droneData?.device_info}-${droneData?.last_flight}-${droneData?.parent?.full_name}-${droneData?.modified}`}
                        >
                            <CardDrone
                                key={`${droneData?.id}-${droneData?.created}-${droneData?.device_info}-${droneData?.last_flight}}-${droneData?.parent?.full_name}-${droneData?.modified}`}
                                droneData={droneData}
                            />
                        </div>
                    );
                })
                }
            </>
        )
    };
    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>

                    <Breadcrumb title={navBarTitle} breadcrumbItem={t("Dashboard")}/>
                    <>

                        {Object.keys(groupedData).map((key) => {
                            { if (Object.keys(groupedData).length>1) {
                                return printWithHeader(key, groupedData[key])
                            } else {
                                return printWoHeader(key, groupedData[key])

                            }
                            }


                        })}

                    </>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
