import React, {createContext, useState, useEffect, useContext, ReactNode, useCallback} from 'react';
import {getMapElementsApi, getMapElementsGroupApi} from "../helpers/sarlink_backed";
import {IMapElement, IMapElementGroup} from "../Types/Common";
import {toast} from "react-toastify";
import {useRecoilState, useRecoilValue} from "recoil";
import {AtomMapGroup, AtomWorkspace} from "../Types/atoms";
import {useSharedWebSocket} from "./WebsocketProvider";



interface MapElementsContextType {
    mapElements: IMapElement[];
    setMapElements: React.Dispatch<React.SetStateAction<IMapElement[]>>;

    mapElementGroups: IMapElementGroup[];
    setMapElementGroups: React.Dispatch<React.SetStateAction<IMapElementGroup[]>>;
}

const MapElementsContext = createContext<MapElementsContextType | null>(null);

export const useMapElements = () => {
    const context = useContext(MapElementsContext);
    if (!context) {
        throw new Error('useMapElementsContext must be used within a MapProvider');
    }
    return context;
    // const { mapElements, setMapElements } = context;
    // return [mapElements as IMapElement[], setMapElements as React.Dispatch<React.SetStateAction<IMapElement[]>>];
};
export const useMapElementsGroup = (): [any[], React.Dispatch<React.SetStateAction<any[]>>] => {
    const context = useContext(MapElementsContext);
    if (!context) {
        throw new Error('useMapElementsGroup must be used within a MapElementsProvider');
    }
    const { mapElementGroups, setMapElementGroups } = context;
    return [mapElementGroups as IMapElementGroup[], setMapElementGroups as React.Dispatch<React.SetStateAction<IMapElementGroup[]>>];
};


interface MapProviderProps {
    children: ReactNode;
}

export const MapElementsProvider: React.FC<MapProviderProps> = ({ children }) => {
    const [mapElements, setMapElements] = useState<IMapElement[]>([]);
    const [mapGroup, setMapGroup] = useRecoilState(AtomMapGroup);
    const {sendMessage, lastMessage, readyState} = useSharedWebSocket();
    const workspace = useRecoilValue(AtomWorkspace);
    const [mapElementGroups, setMapElementGroups] = useState<IMapElementGroup[]>([]);

    useEffect(() => {
        // Check if geolocation is supported by the browser
        getMapElementGroups();



    }, []);

    useEffect(() => {
        if (!workspace) return;
        //console.log("workspace changed", workspace)
        getMapElementGroups();
    }, [workspace]);
    const getMapElementGroups = useCallback(
        async () => {
            if (!workspace) return;
            getMapElementsGroupApi(workspace.id).then((res: IMapElementGroup[]) => {
                //console.log("mapElementGroup", res.data)
                setMapElementGroups(res)
                //console.log(mapGroup)
                if (mapGroup) {
                    const group = res.find((item: IMapElementGroup) => item.id === mapGroup.id)
                    //console.log("group", group)
                    if (!group) {
                        setMapGroup(res[0])
                    }
                }
                if (res.length > 0 && !mapGroup) {
                    setMapGroup(res[0])
                }
                if (mapGroup === null) {
                    //console.log("mapGroup1", mapGroup)
                    //console.log(res.data)
                    setMapGroup(res[0])
                }

                getMapElements();
                //console.log(mapGroup?.name)
                return res
            }).catch((err: unknown) => {
                toast.error("Error loading map elements")
            })

        }, [workspace]
    );
    useEffect(() => {
        if (lastMessage === null) return;
        //console.log("lastMessage", lastMessage)
        const data = JSON.parse(lastMessage?.data)
        const biz_code = data.biz_code;
        if (["map_element_update", "map_element_create", "map_element_delete"].includes(biz_code)) {
            //console.log("map_element", data)
            const name = data.data.name;
            //console.log("name", name)
            let toast_msg = `Map element changed (${name})`;
            if (biz_code === "map_element_create") {
                toast_msg = `Map element created (${name})`;
            }
            if (biz_code === "map_element_delete") {
                toast_msg = `Map element deleted`;
            }

            toast.info(toast_msg, {autoClose: 2000, toastId: biz_code})
            getMapElements();
        }


    }, [lastMessage]);
    const getMapElements = useCallback(
        async () => {
            if (!mapGroup) return;
            await getMapElementsApi(mapGroup.id).then((res: IMapElement[]) => {
                console.log("setMapElements", res)

                setMapElements(res)
                return res
            }).catch((err: unknown) => {
                toast.error("Error loading map elements")
            })

        }, [mapGroup]
    );
    useEffect(() => {
        console.log("useEffect mapGroup changed", mapGroup)
        getMapElements();
    }, [mapGroup]);

    useEffect(() => {
        // fetch('/mapelements')
        //     .then(response => response.json())
        //     .then(data => setMapElements(data))
        //     .catch(error => console.error('Error fetching map elements:', error));
        setMapElements([]);
    }, []);

    return (
        <MapElementsContext.Provider value={{ mapElements, setMapElements,mapElementGroups,setMapElementGroups }}>
            {children}
        </MapElementsContext.Provider>
    );
};
