import {GeoJSONPoint} from "../helpers";

export enum EWsMsgType {
    USER_LOCATION = 'user_location',
    DASHBOARD = 'dashboard',
    STREAM_CHANGED = 'stream_changed',
    DRONE = 'drone',
    RC = 'rc',
    LAST_LOCATION = 'last_location',
    LAST_LOCATION_RC = 'last_location_rc',
    BATTERY = 'battery',
    PAYLOAD = 'payload',

}
export interface IWsDroneEvent {
    sn: string;
    parsed: string;
    code_hex: string;
    code: number;
    unknown: boolean;
}
export interface WsMessage {
    msg_type: EWsMsgType;
    timestamp: number;
    datetime: string;
    device_id: number|null;
    data: { [key: string]: any };
    data_list: any[]|null;
    recipients: number[];
}

export interface IWsUser {
    id: number;
    initials: string;
    email: string;
    company: string;
}
export interface WsMessageUserLocation {

    user: IWsUser;
    browser_id: string;
    location: GeoJSONPoint;
    accuracy: number;
    altitude: number | null;
    altitude_accuracy: number | null;
    heading: number | null;
    speed: number | null;
    timestamp: number;

}