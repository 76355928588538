import React, { useState } from "react";

import { IDeviceCamera, IStream } from "../../Types/Common";
import { BsCameraVideoOff } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StreamBadge from "./StreamBadge";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import VideoRTC from "../../Components/VideoPlayers/VideoRTC";
import { DeviceCameraStartLiveApi } from "../../helpers/sarlink_backed";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";

const StreamCard: React.FC<IStream> = (
  { ...stream }: IStream,
  show_rtmp = true
) => {
  // Use TypeScript annotations for useState
  //const [playingStreams, setPlayingStreams] = useRecoilState(AtomPlayingStreams);
  const {t} = useTranslation();

  const [singlebtn, setSinglebtn] = useState(false);

  // const playStream = (newItem: string) => {
  //     setPlayingStreams((prevItems) => [...prevItems, newItem]);
  // };
  // const stopStream = (valueToRemove: string) => {
  //     setPlayingStreams((prevItems) => prevItems.filter(item => item !== valueToRemove));
  // };

  const openLarix = (stream: IStream) => {
    const rtmp_url = encodeURI(stream.rtmp_url);
    try {
      const url = `larix://set/v1?conn[][url]=${rtmp_url}%2F&conn[][name]=pgd-velenje%2Fuwb68f08&conn[][overwrite]=on&enc[vid][multiCam]=pip&enc[vid][background]=on`;
      console.log(url);
      //window.location.assign(url)
      window.open(url);
    } catch (e) {
      toast.error(JSON.stringify(e));
      toast.error("Larix app not installed");
    }
  };
  const startLiveStream = (camera_id: number) => {
    console.log(camera_id);
    DeviceCameraStartLiveApi(camera_id)
      .then((response) => {
        console.log(response);
        toast.success("Live stream started");
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };
  // const stopLiveStream = (camera_id: number) => {
  //     console.log(camera_id)
  //     DeviceCameraStopLiveApi(camera_id).then((response) => {
  //         console.log(response)
  //         toast.success("Live stream stopped")
  //
  //     }).catch((err) => {
  //         toast.error(JSON.stringify(err))
  //     })
  // }
  // const stopLiveStreamall = (camera_id: number) => {
  //     console.log(camera_id)
  //     DeviceCameraStopAllApi(camera_id).then((response) => {
  //         console.log(response)
  //         toast.success("Live stream stopped")
  //
  //     }).catch((err) => {
  //         toast.error(JSON.stringify(err))
  //     })
  // }
  return (
    <Card key={`/video/${stream.id}`} className={"h-100 m-2"}>
      <CardBody>
        <CardTitle tag="h4">
          {stream.name} <small>{stream.model_name}</small>
        </CardTitle>
        <Row className={"card-text"}>
            {stream.is_live ? (
              <VideoRTC stream_id={stream.id}></VideoRTC>
            ) : (
              <BsCameraVideoOff
                width={"100%"}
                className={"color-primary"}
                style={{
                  marginTop: "48px",
                  width: "60px",
                  height: "60px",
                  verticalAlign: "middle",
                }}
              ></BsCameraVideoOff>
            )}
          <StreamBadge {...stream}></StreamBadge>
        </Row>
        <CardText>
          <div className="d-flex flex-wrap gap-2">
            {!stream.is_live && (
              <>
                <p>RTMP URL: {stream.rtmp_url}</p>
              </>
            )}
            <>
              {stream.devicecamera?.length !== 0 && (
                <Dropdown
                  isOpen={singlebtn}
                  toggle={() => setSinglebtn(!singlebtn)}
                >
                  <DropdownToggle className="btn btn-secondary" caret>
                    {t("Start Live Stream")}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {stream.devicecamera?.map((camera: IDeviceCamera) => (
                      <DropdownItem
                        key={camera.id}
                        onClick={() => startLiveStream(camera.id)}
                      >
                        {camera.device_info}
                      </DropdownItem>
                    ))}

                    {/*{stream.devicecamera?.map((camera: IDeviceCamera) => {*/}
                    {/*    <DropdownItem onClick={startLiveStream(camera.id)}>{camera.device_info}</DropdownItem>*/}

                    {/*})}*/}
                  </DropdownMenu>
                </Dropdown>
              )}
            </>
          </div>
        </CardText>
      </CardBody>
    </Card>
  );
};
export default React.memo(StreamCard, isEqual);
