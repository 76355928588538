import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {LoginApi} from "../../helpers/sarlink_backed";
import {ErrorResponse, ILogin} from "../../Types/Common";
import {clearLocalTokens, setLocalAccessToken, setLocalRefreshToken} from "../../helpers/api_helper";
import {toast} from "react-toastify";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios/index";
import {Link} from "react-router-dom";

import {

    Form,
    Input,
    Label,
    FormFeedback,
    Alert,
    Spinner,

} from "reactstrap";
const LoginNormal = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const selectProperties = createSelector(
        (state: any) => state.Login,
        (error) => error
    );
    const {error} = useSelector(selectProperties);
    const doLogin = (user_data: any, history: any): void => {
        //event.preventDefault();
        console.log("doLogin()")
        setIsLoading(true);
        LoginApi({email: user_data.email, password: user_data.password})
            .then((response: ILogin) => {
                clearLocalTokens();

                setLocalAccessToken(response.access);
                setLocalRefreshToken(response.refresh);
                //setAccess_token(data.access);
                //setRefresh_token(data.refresh);
                if (window.djiBridge !== undefined) {
                    navigate("/pilot-login")
                } else {
                    navigate("/")
                }

                //window.location.href = '/';
            }).catch((error: AxiosError<ErrorResponse>) => {
            if (error.response && error.response.data) {
                const errors: ErrorResponse = error.response.data;
                toast.error("Credentials are not valid.");

            } else {
                // Handle other types of errors, if needed
                console.log("error", error)
                toast.error("An unexpected error occurred.");
            }
        }).finally(() => {
            setIsLoading(false)
        });


    }
    // Form validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values: any) => {
            doLogin(values, props.router.navigate);
        }
    });
    return (
        <Form className="form-horizontal"
              onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
              }}
        >
            <div className="mb-3">
                {error ? <Alert color="danger">{error}</Alert> : null}
                <Label className="form-label">Email</Label>
                <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                        validation.touched.email && validation.errors.email ? true : false
                    }
                />
                {validation.touched.email && validation.errors.email ? (
                    <FormFeedback
                        type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
            </div>

            <div className="mb-3">
                <Label className="form-label">Password</Label>
                <div className="input-group auth-pass-inputgroup">
                    <Input
                        name="password"
                        value={validation.values.password || ""}
                        type={show ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                            validation.touched.password && validation.errors.password ? true : false
                        }
                    />
                    <button onClick={() => setShow(!show)} className="btn btn-light "
                            type="button" id="password-addon">
                        <i className="mdi mdi-eye-outline"></i></button>
                </div>
                {validation.touched.password && validation.errors.password ? (
                    <FormFeedback
                        type="invalid">{validation.errors.password}</FormFeedback>
                ) : null}
            </div>



            <div className="mt-3 d-grid">
                {isLoading ? (<Spinner></Spinner>) : (<button
                    className="btn btn-primary btn-block "
                    type="submit"
                >
                    Log In
                </button>)}
            </div>


            <div className="mt-4 text-center">
                <Link to="/forgot-password" className="text-muted">
                    <i className="mdi mdi-lock me-1"/> Forgot your
                    password?
                </Link>
            </div>
        </Form>
    )
}
export default LoginNormal