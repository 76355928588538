import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {IStream} from "../../Types/Common";
import {useLocation, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {AxiosRequestConfig} from "axios/index";

import {StreamGetApi} from "../../helpers/sarlink_backed";
import {Button} from "reactstrap";

// URL for the OSSRS server with WHIP support
interface PacketsLost {
    [type: string]: number;
}

interface StreamStats {
    bitrate?: string
    rtt?: string;
    packetsLost?: PacketsLost;
}

interface MyComponentProps {
    stream_id?: string;
}

const VideoPlayerRTC: React.FC<MyComponentProps> = ({stream_id}) => {
    const streamLocation = useLocation();
    const params = useParams();
    const [streamId, setStreamId] = useState<string>()
    const videoRef = useRef<HTMLVideoElement>(null);
    const [url, setUrl] = useState<string>();
    const packetsLost: PacketsLost = {video: 0, audio: 0};
    const [bitrate, setBitrate] = useState<string>();
    const [rtt, setRtt] = useState<string>();
    const [streamStats, setStreamStats] = useState({});
    const [stream, setStream] = useState<IStream>();
    const [videoError, setVideoError] = useState<Error>();

    useEffect(() => {
        console.log("streamId", streamId)
        if (streamId === undefined) return;
        StreamGetApi(streamId).then((response: IStream) => {
            setStream(response);
            setUrl(response.rtc_url);
            console.log("set url:" + response.rtc_url)
        });

    }, [streamId]);
    useEffect(() => {
        console.log("useEffect", streamLocation.state?.id, stream_id, params.id)
        if (streamLocation.state?.id !== undefined) {
            setStreamId(streamLocation.state.id)

        } else if (stream_id !== undefined) {
            setStreamId((stream_id))

        } else if (params.id !== undefined) {
            setStreamId((params.id))

        } else {
            throw new Error("invalid streamLocation")
        }
        if (url === undefined) {
            return;
        }
    }, []);

    const playStream = async () => {
        console.log("playStream", videoRef.current)
        await setVideoError(undefined)
        if (videoRef.current) {
            loadVideo();
        } else {
            console.log("videoRef.current is null")
        }
    }
    const loadVideo = () => {
        if (url === undefined) return;
        //if (url.indexOf('/whip') === -1) throw new Error(`invalid WHIP url ${url}`);
        console.log("stream ids", streamLocation.state?.id, stream_id, params.id)

        const peerConnection = new RTCPeerConnection();
        setInterval(() => {
            peerConnection.getStats(null).then((stats) => {
                let statsOutput = "";
                //console.log(stats)

                stats.forEach((report) => {
                    if (report.type === "candidate-pair") {
                        //console.log(report)
                        if (report.nominated) {
                            setStreamStats({...streamStats, rtt: `RTT: ${report.currentRoundTripTime * 1000}ms`})
                            if (report.availableIncomingBitrate) {
                                setBitrate(`Bitrate: ${Math.round(
                                    report.availableIncomingBitrate / 1000
                                )}kbps`)
                                //console.log("streamStats bitrate")
                                setStreamStats({
                                    ...streamStats, bitrate: `Bitrate: ${Math.round(
                                        report.availableIncomingBitrate / 1000
                                    )}kbps`
                                })

                            }

                        }


                    }
                    if (report.type === "inbound-rtp") {
                        //console.log(report)
                        if (report.kind === 'video' || report.kind === 'audio') {
                            packetsLost[report.kind] = report.packetsLost + 1001;

                        }
                        //console.log(packetsLost)

                        //console.log(streamStats)
                        setStreamStats({...streamStats, packetsLost: packetsLost})
                        //console.log(streamStats)
                        //console.log({...streamStats, packetsLost: packetsLost})

                    }
                });

            });
            //console.log(streamStats)
        }, 5000);

        peerConnection.onicecandidate = (event) => {
        };
        peerConnection.ontrack = (event) => {
            //setStreams(event.streams);
            ////console.log(event)
            ////console.log("Got remote track:", event.streams[0])

            if (videoRef.current && event.streams[0]) {
                videoRef.current.srcObject = event.streams[0];

            }
        };
        //peerConnection.addTransceiver("audio", {direction: "recvonly"});
        peerConnection.addTransceiver("video", {direction: "recvonly"});
        ////console.log(peerConnection.connectionState)
        const offer = peerConnection.createOffer();
        offer.then(async (offer) => {
            console.log("Generated offer: ", offer);
            await peerConnection.setLocalDescription(offer);

            ////console.log(offer.sdp)
            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/sdp',
                }
            };

            ////console.log(peerConnection.connectionState)
            //console.log(offer.sdp)
            //console.log(url)
            axios.post(url, offer.sdp, config)
                //DataService.StreamPlay(streamId, offer.sdp)
                .then(async (response) => {
                    // The response from the server will vary depending on its implementation
                    // You might need to adjust the following lines
                    console.log("Got answer: ", response);
                    let myObj: RTCSessionDescriptionInit = {sdp: response.data, type: "answer"};

                    const remoteOffer = new RTCSessionDescription(myObj);
                    console.log("remoteOffer", remoteOffer)
                    await peerConnection.setRemoteDescription(remoteOffer);
                    console.log(peerConnection.connectionState)
                    //const answer = await peerConnection.createAnswer();
                    //await peerConnection.setLocalDescription(answer);
                    //videoRef.current.play();
                    // Here, send this answer back to the server via whatever mechanism
                    // the OSSRS server expects (WebSocket, HTTP, etc.)
                    if (!videoRef.current) {
                        console.log("videoRef.current is null")
                        return;
                    }
                    ;

                    videoRef.current.play()
                        .then(() => {
                            console.log("play success")
                            setVideoError(undefined)
                        })
                        .catch((e) => {
                            console.log("play error", e)
                            setVideoError(e)
                        });
                })
                .catch((error) => {
                    toast(error.error);
                    console.error("Error fetching data:", error);
                });
        }).catch(reason => {
            console.log("createOffer failed", reason)
        });

        // Fetch SDP and ICE candidate information from the OSSRS server


        return () => {
            // Cleanup
            //console.log("cleanup videortc",streamId)
            peerConnection.close();
        };
    }
    useEffect(() => {
        //console.log(params)
        //console.log(streamLocation)
        loadVideo();
    }, [url]);

    return (
        <>

                <video className={"video_player"} preload="auto" ref={videoRef} webkit-playsinline playsInline controls={false} muted={true}></video>
        {videoError !== undefined && (

            <Button
                tag="button"
                className="btn btn-primary"
                onClick={playStream}
            >
                <i className="bx bx-play align-middle me-1" /> Play stream
            </Button>

        )}
        </>
    );
};

export default VideoPlayerRTC;
