// WebSocketContext.tsx
import React, { createContext, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {WEBSOCKET_URL} from "../constants";
import {getLocalAccessToken} from "../helpers/api_helper";
import * as Sentry from "@sentry/react";

interface WebSocketContextValue {
    sendMessage: (message: string) => void;
    lastMessage: any;
    readyState: ReadyState;
}

const WebSocketContext = createContext<WebSocketContextValue | null>(null);

export const useSharedWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useSharedWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

interface WebSocketProviderProps {
    children: React.ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({  children }) => {
    const [socketUrl, setSocketUrl] = React.useState<string>(WEBSOCKET_URL + "?x-auth-token=" + getLocalAccessToken());
    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(socketUrl,{
        // onOpen: () => console.log('opened'),
        // onClose: () => console.log('closed'),
        onError: (event) => {

            Sentry.captureException(Error('Websocket Error'));

        },
        // //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => {
            console.log('close event', closeEvent)
            setSocketUrl(WEBSOCKET_URL + "?x-auth-token=" + getLocalAccessToken())

            return true;
        },
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            timeout: 30000, // 30 seconds, if no response is received, the connection will be closed
            interval: 10000, // every 10 seconds, a ping message will be sent
        },
        reconnectInterval: (attemptNumber) =>
            Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
        onReconnectStop: () => new Error('Websocket Reconnect failed. Refresh the page.'),
        reconnectAttempts: 1000,
        share: false,
    });

    return (
        <WebSocketContext.Provider value={{ sendMessage, lastMessage, readyState }}>
            {children}
        </WebSocketContext.Provider>
    );
};
