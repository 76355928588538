import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
} from "reactstrap";

//i18n
import {useTranslation, withTranslation} from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import { createSelector } from "reselect";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { useSelector } from "react-redux";
import { useRecoilState, useRecoilValue } from "recoil";
import { SwitchCompany, UserInfo, VisitorId } from "../../../Types/atoms";
import { API_URL, APP_URL } from "../../../constants";

import QRCode from "react-qr-code";
import { ISwitchCompany } from "../../../Types/Common";
import { activateCompanyApi } from "../../../helpers/sarlink_backed";
import { toast } from "react-toastify";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const userInfo = useRecoilValue(UserInfo);
  const switchCompany = useRecoilValue(SwitchCompany);
  const [modal_standard, setmodal_standard] = useState(false);
const {t}=useTranslation()
  const profile = (state: any) => state.Profile;
  const selectProfileProperties = createSelector(profile, (success) => success);

  const { success } = useSelector(selectProfileProperties);

  const setNewCompany = (id: any) => {
    activateCompanyApi(id)
      .then((res: any) => {
        window.location.href = "/";
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          setmodal_standard(!modal_standard);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal_standard(!modal_standard);
          }}
        >
          <div className="modal-title mt-0 h5" id="myModalLabel">
            Fast login with login key
          </div>
        </ModalHeader>
        <div className="modal-body">
          <p>
            Scan QR code:
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "80%", width: "80%" }}
              value={`${APP_URL}login/${userInfo?.login_query_string}`}
              //value={"hey"}
              viewBox={`0 0 256 256`}
            />
          </p>
          <p>
            Login key: <b>{userInfo?.login_key}</b>
          </p>
          <p>
            Login link:{" "}
            <a
              href={`${APP_URL}login/${userInfo?.login_query_string}`}
            >{`${APP_URL}login/${userInfo?.login_query_string}`}</a>
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setmodal_standard(!modal_standard);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/*<img*/}
          {/*  className="rounded-circle header-profile-user"*/}
          {/*  src={user1}*/}
          {/*  alt="Header Avatar"*/}
          {/*/>*/}
          {/*  TODO:ADD user image*/}
          <div className="avatar-xs me-3">
            <span
              className={`avatar-title bg-primary rounded-circle font-size-16`}
            >
              {userInfo?.initials}
            </span>
            <i className="mdi mdi-chevron-down d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#">
            {" "}
            {t("Hi, ") + userInfo?.email}
            <br />
            {userInfo?.company.name}
          </DropdownItem>

          <DropdownItem
            tag="a"
            href="#"
            onClick={() => {
              setmodal_standard(!modal_standard);
            }}
          >
            <i className="bx bx-key font-size-16 align-middle me-1" />
            {props.t("Show login key")}
          </DropdownItem>
          <div className="dropdown-divider" />
          {switchCompany.length > 0 && <div className={"ms-2"}>{t('Switch to')}:</div>}
          {switchCompany.length === 0 && (
            <div className={"ms-2"}>No shared entities to switch</div>
          )}
          {switchCompany?.map((company: ISwitchCompany, key: any) => {
            if (company.company.id === userInfo?.company.id) {
              return null;
            } else {
              return (
                <DropdownItem
                  tag="a"
                  href="#"
                  onClick={() => {
                    setNewCompany(company.id);
                  }}
                  key={key}
                >
                  <i className="bx bx-building font-size-16 align-middle me-1" />
                  {company.company.name}
                </DropdownItem>
              );
            }
          })}
          {/*<DropdownItem tag="a" href="#">*/}
          {/*  <span className="badge bg-success float-end">11</span>*/}
          {/*  <i className="bx bx-wrench font-size-16 align-middle me-1" />*/}
          {/*  {props.t("Settings")}*/}
          {/*</DropdownItem>*/}
          {/*<DropdownItem tag="a" href={import.meta.env.PUBLIC_URL + "/auth-lock-screen"}>*/}
          {/*  <i className="bx bx-lock-open font-size-16 align-middle me-1" />*/}
          {/*  {props.t("Lock screen")}*/}
          {/*</DropdownItem>*/}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
