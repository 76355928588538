import React, {CSSProperties, PureComponent, useCallback, useEffect, useRef, useState} from "react";
import {
    Autocomplete, Data,
    DrawingManager, DrawingManagerF, GoogleMap, OverlayView,
    StandaloneSearchBox,

} from "@react-google-maps/api";
import {iMapProps} from "./Types";
import {convertMarkerToGeoJSON, convertPolygonToGeoJSON, convertRectangleToGeoJSON} from "../../../helpers";
import {IMapElement, IMapElementCreate} from "../../../Types/Common";
import MapFeature from "./Google/MapFeature";
import {FaLocationCrosshairs,} from "react-icons/fa6";
import {toast} from "react-toastify";
import {useRecoilState, useRecoilValue} from "recoil";
import {ActiveMapElement, AtomMapGroup, AtomMapFitBounds, AtomMyLocation} from "../../../Types/atoms";
import {DefaultMarkerIcon, MapElementColors} from "../../../constants";
import {Button, ButtonGroup, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {mapElementCreateApi} from "../../../helpers/sarlink_backed";
import {LocationState} from "../../../Providers/LocationProvider";
import IconFirefighter from "../../../assets/firefighter-svgrepo-com.svg";
import IconUser from "../../../assets/user-map-location-icon.svg";

import parse from 'html-react-parser';

const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '50px',
    right: '10px',
}


const MapGoogleProvider: React.FC<iMapProps> = ({
                                                    additionalMarkers,
                                                    center_location,
                                                    mapElements,
                                                    zoom,
                                                    markerBounds,
                                                    isLoaded,
                                                    hudHtml,
                                                }) => {
    const [activeMarker, setActiveMarker] = useRecoilState(ActiveMapElement);
    const [mapElementsState, setMapElementsState] = useState<IMapElement[]>([]);
    const mapGroup = useRecoilValue(AtomMapGroup);
    const [newElementName, setNewElementName] = useState('');
    const [newElementColor, setNewElementColor] = useState(MapElementColors.BLUE);
    const [showModalElementName, setShowModalElementName] = useState(false);
    const [newElement, setNewElement] = useState<IMapElementCreate | null>(null);
    const [googleMapsDrawing, setGoogleMapsDrawing] = useState<google.maps.Polygon | google.maps.Rectangle | google.maps.Marker | google.maps.Polyline | null>(null)
    const mapRef = useRef<GoogleMap>();
    const [mapFitBounds, setMapFitBounds] = useRecoilState<google.maps.Map | undefined>(AtomMapFitBounds)
    const autocompleteRef = useRef<Autocomplete>();
    const refDrawingManager = useRef<google.maps.drawing.DrawingManager>();
    const [userLocationMarker, setUserLocationMarker] = useState<IMapElement | null>(null)
    const [myLocation, setMyLocation] = useRecoilState<LocationState | null>(AtomMyLocation);
    const [stateDrawingManager, setStateDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null);
    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds({
            lat: center_location.latitude,
            lng: center_location.longitude
        });
        mapRef.current = map
        //console.log("map", map)
        //
        // map.addListener("center_changed", () => {
        //     //console.log("center_changed", map.getCenter().lat(), map.getCenter().lng())
        // })
        // map.addListener("zoom_changed", () => {
        //     //console.log("zoom_changed", map.getZoom())
        // })
        // map.addListener("bounds_changed", () => {
        //     //console.log("bounds_changed", map.getBounds())
        // })


    }, [])

    useEffect(() => {
        if (!mapRef.current) return;
        const map_instance = (mapRef.current as unknown as google.maps.Map)

        //setMapRefRecoil(mapRef.current)
    }, [mapRef.current])
    useEffect(() => {
        if (!activeMarker) return;
        const map_instance = (mapRef.current as unknown as google.maps.Map)
        //console.log(activeMapElement.bounds)
        // (xmin w, ymin s, xmax e, ymax n)
        const b = {
            east: activeMarker.bounds[2],
            north: activeMarker.bounds[3],
            south: activeMarker.bounds[1],
            west: activeMarker.bounds[0]
        }
        //map_instance.fitBounds(b)

    }, [activeMarker])
    useEffect(() => {
        if (markerBounds === null) return;
        if (!isLoaded) return;
        if (!mapRef) return;
        //console.log("markerBounds", markerBounds)
        //console.log("mapRef", mapRef)
        const map_instance = (mapRef.current as unknown as google.maps.Map)
        //console.log("map_instance", map_instance)
        if (!map_instance) return;
        map_instance.fitBounds(markerBounds as google.maps.LatLngBounds)
        //mapRef.fitBounds(markerBounds);
        //map.fitBounds(bounds);


        // if (mapRef.current) {
        //     const bounds = new window.google.maps.LatLngBounds();
        //
        //     // Example locations to fit bounds
        //     const locations = [
        //         { lat: 40.730610, lng: -73.935242 },
        //         { lat: 40.731610, lng: -73.945242 },
        //     ];
        //
        //     locations.forEach(location => {
        //         bounds.extend(location);
        //     });
        //     //console.log("bounds", bounds)
        //
        //     //fitBounds(bounds);
        //     mapRef.current.fitBounds(bounds);
        // }
    }, [mapRef.current, markerBounds])
    const onLoadDrawingManager = (drawingManager: any) => {
        //console.log("onLoadDrawingManager", drawingManager)
        refDrawingManager.current = drawingManager;
        google.maps.event.addListener(drawingManager, 'overlaycomplete', onOverlayComplete);

    }

    const onOverlayComplete = (event: google.maps.drawing.OverlayCompleteEvent) => {
        //console.log("onOverlayComplete", event)
    }

    const handleActiveMarker = (marker: any) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const searchBoxCallback = (searchBox: any) => {
    }

    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        //console.log("onPolygonComplete", polygon)
        //console.log(convertPolygonToGeoJSON(polygon))
        if (!mapGroup) {
            toast.error("Please select map group")
            return
        }
        setGoogleMapsDrawing(polygon)

        const create_element: IMapElementCreate = {
            name: "New element",
            color: newElementColor,
            group: mapGroup?.id,
            polygon: convertPolygonToGeoJSON(polygon)
        }

        setNewElement(create_element)
        setShowModalElementName(true)
    }
    const onRectangleComplete = (polygon: google.maps.Rectangle) => {
        //console.log(convertRectangleToGeoJSON(polygon))
        if (!mapGroup) {
            toast.error("Please select map group")
            return
        }
        setGoogleMapsDrawing(polygon)

        const create_element: IMapElementCreate = {
            name: "New element",
            color: newElementColor,
            group: mapGroup?.id,
            polygon: convertRectangleToGeoJSON(polygon)
        }

        setNewElement(create_element)
        setShowModalElementName(true)


    }
    const onMarkerComplete = (marker: google.maps.Marker) => {
        //console.log("onMarkerComplete", marker)
        if (!mapGroup) {
            toast.error("Please select map group")
            return
        }
        setGoogleMapsDrawing(marker)
        //console.log(newElementColor)
        const create_element: IMapElementCreate = {
            name: "New element",
            group: mapGroup?.id,
            color: newElementColor,
            point: convertMarkerToGeoJSON(marker)
        }

        setNewElement(create_element)
        setShowModalElementName(true)


    }
    const onPolylineComplete = (polyline: google.maps.Polyline) => {
        //console.log("onPolylineComplete", polyline)


    }
    const onCompleteNotImplemented = (e: any) => {
        //console.log("onCompleteNotImplemented", e)
    }
    useEffect(() => {
        if (!myLocation) return;
        //setCenter({lat: location.latitude, lng: location.longitude})
        isLoaded && setUserLocationMarker({
            id: "userLocationMarker",
            group: "userLocationMarker",
            name: "Moja lokacija",
            center: {
                latitude: myLocation.latitude,
                longitude: myLocation.longitude
            },
            point: {
                type: "Point",
                coordinates: [myLocation.longitude, myLocation.latitude]
            },
            bounds: [myLocation.longitude, myLocation.latitude, myLocation.longitude, myLocation.latitude],
            color: "red",
            color_display: "red",
            icon: {
                scaledSize: new google.maps.Size(30, 30),
                url: IconUser,
                labelOrigin: new google.maps.Point(15, 40),
            },

        })
    }, [myLocation, isLoaded])


    const onLoadAutocomplete = (autocomplete: any) => {
        //console.log('autocomplete: ', autocomplete)
        autocompleteRef.current = autocomplete
        setAutocompleteState(autocomplete.state)
    }
    const [autocompleteState, setAutocompleteState] = useState(null)
    const onPlaceChanged = () => {
        //console.log(autocompleteRef.current)
        if (autocompleteRef.current) {
            const autocomplete = (autocompleteRef.current as unknown as google.maps.places.Autocomplete)
            //console.log("autocompleteState.getPlace()", autocomplete.getPlace())
        } else {
            //console.log('Autocomplete is not loaded yet!')
        }
    }
    const zoomMyLocation = () => {
        //console.log("zoomMyLocation", mapRef.current)

        if (!mapRef.current) return;
        if (!userLocationMarker) return;
        //console.log("userLocationMarker", userLocationMarker.center)
        //mapRef.current.panTo({lat: userLocationMarker.center.latitude, lng: userLocationMarker.center.longitude})
        const map_instance = (mapRef.current as unknown as google.maps.Map)
        map_instance.setCenter({lat: userLocationMarker.center.latitude, lng: userLocationMarker.center.longitude})
        map_instance.setZoom(18)
        //map_instance?.setZoom(4)
    }

    const handleCloseModalElementName = () => {
        googleMapsDrawing?.setMap(null)

        setShowModalElementName(false)
    };
    const handleSubmitModalElementName = () => {
        if (!newElement) return;
        newElement.name = newElementName;
        newElement.color = newElementColor;
        mapElementCreateApi(newElement).then(() => {
            //console.log("mapElementCreate", res)
            setShowModalElementName(false);
            setNewElement(null)
            //console.log("googleMapsDrawing", googleMapsDrawing)
            googleMapsDrawing?.setMap(null)
        }).catch((err) => {
            console.error("mapElementCreate", err)
            googleMapsDrawing?.setMap(null)

            toast.error("Error creating map element " + JSON.stringify(err.response.data))
        })

    }
    const handleChangeColor = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setNewElementColor(event.target.value as MapElementColors);
    };


    const toggle_modal = () => {
        setShowModalElementName(!showModalElementName)
    }
    return isLoaded ? (
        <GoogleMap
            mapContainerClassName={"h-75 min-vh-50"}
            /* center={location.latitude && location.longitude ? {
                 lat: location.latitude,
                 lng: location.longitude
             } : {lat: 0, lng: 0}}*/
            //center={{lat: 0, lng: 0}}
            // center={center}
            zoom={zoom}
            onLoad={onLoad}
            mapTypeId={"satellite"}

        >
            <ButtonGroup style={{

                padding: `0 12px`,


                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                right: "0px",
                bottom: "200px",
                //bottom: "30px",
                marginLeft: "-120px",

            }} className={"float-end"}>
                <Button variant={"light"} onClick={() => {
                    zoomMyLocation()
                    //console.log("click")
                }}><FaLocationCrosshairs></FaLocationCrosshairs></Button>
            </ButtonGroup>
            <div style={{

                display: "inline-flex",
                position: "absolute",
                left: "0px",
                bottom: "0%",
                width: "20%",
                zIndex: 1000002,

            }} className={"d-md-none d-lg-block"}>{hudHtml}</div>

            <Modal isOpen={showModalElementName}
                   toggle={() => {
                       toggle_modal();
                   }}>

                <ModalHeader></ModalHeader>
                <div className="modal-body">
                    <div className="form-group">
                        <label htmlFor="newElementName">Name</label>
                        <input type="text" className="form-control" id="newElementName"
                               placeholder="Enter element name"
                               value={newElementName}
                               onChange={(e) => {
                                   setNewElementName(e.target.value)
                               }}
                        />
                    </div>
                    <div>
                        <label htmlFor="newElementColor">Color</label>
                        <select className="form-control" id="newElementColor" value={newElementColor}
                                onChange={handleChangeColor}>
                            <option value="" disabled>Select a color</option>
                            {Object.entries(MapElementColors).map(([colorName, colorValue]) => (
                                <option style={{color: colorValue}} key={colorName} value={colorValue}>
                                    {colorName}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
                <ModalFooter>
                    <Button variant="secondary" onClick={handleCloseModalElementName}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitModalElementName}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>


            {additionalMarkers?.map(item => <MapFeature key={`additionalMarkers-${item.id}-${item.modified}`} {...item}></MapFeature>)}


            {mapElements?.map(item => <MapFeature key={`mapElements-${item.id}-${item.modified}`} {...item}></MapFeature>)}
            {/*{userLocationMarker && <MapFeature key={"mylocation"} {...userLocationMarker} ></MapFeature>}*/}


            <DrawingManagerF
                options={{
                    drawingControl: true,
                    markerOptions: {
                        icon: DefaultMarkerIcon
                    },

                    drawingControlOptions: {

                        drawingModes: [
                            google.maps.drawing.OverlayType.MARKER,
                            google.maps.drawing.OverlayType.POLYLINE,
                            google.maps.drawing.OverlayType.POLYGON,
                            google.maps.drawing.OverlayType.RECTANGLE,
                        ],
                    }
                }}
                onLoad={onLoadDrawingManager}
                onPolygonComplete={onPolygonComplete}
                onRectangleComplete={onRectangleComplete}
                onCircleComplete={onCompleteNotImplemented}
                onPolylineComplete={onPolylineComplete}
                onMarkerComplete={onMarkerComplete}
                onOverlayComplete={onCompleteNotImplemented}
                drawingMode={null}/>

            {/*<StandaloneSearchBox>*/}
            {/*    <input*/}

            {/*        type='text'*/}
            {/*        placeholder='Customized your placeholder'*/}
            {/*        style={inputStyle}*/}
            {/*    />*/}

            {/*</StandaloneSearchBox>*/}

            {/*<Autocomplete*/}
            {/*    onLoad={onLoadAutocomplete}*/}
            {/*    onPlaceChanged={onPlaceChanged}*/}
            {/*>*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        placeholder="Customized your placeholder auto"*/}
            {/*        style={{*/}
            {/*            boxSizing: `border-box`,*/}
            {/*            border: `1px solid transparent`,*/}
            {/*            width: `240px`,*/}
            {/*            height: `32px`,*/}
            {/*            padding: `0 12px`,*/}
            {/*            borderRadius: `3px`,*/}
            {/*            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,*/}
            {/*            fontSize: `14px`,*/}
            {/*            outline: `none`,*/}
            {/*            textOverflow: `ellipses`,*/}
            {/*            position: "absolute",*/}
            {/*            left: "50%",*/}
            {/*            marginLeft: "-120px"*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Autocomplete>*/}

        </GoogleMap>
    ) : <></>


}
export default MapGoogleProvider;