import React, {useEffect, useState} from "react";
import {
    API_URL,
    APP_URL,
    djiAppId,
    djiAppKey,
    djiAppLicense,
    navBarTitle,
    storageAircraftSN,
    storageMediaParam,
    WEBSOCKET_URL,
} from "../../constants";
import {toast} from "react-toastify";
import DjiBridge, {MapParam} from "../../DjiBridge/DjiBridge";
import djiBridge, {
    ApiParam,
    LiveshareParam,
    MediaParam,
    ThingParam,
    WsParam,
} from "../../DjiBridge/DjiBridge";
import {IDjiVersion} from "../../DjiBridge/Types";
import {
    EComponentName,
    EDownloadOwner,
    EPhotoType,
    EStatusValue,
} from "../../DjiBridge/enums";

import {getLocalAccessToken} from "../../helpers/api_helper";
import {useRecoilState} from "recoil";
import {
    AtomAircraftBindState,
    AtomDjiApiState,
    AtomDjiLiveState,
    AtomDjiMapState,
    AtomDjiMediaState,
    AtomDjiThingState,
    AtomDjiTsaState,
    AtomDjiWaylineState,
    AtomDjiWsState,
    AtomGatewayState,
    AtomMediaParam,
    AtomWorkspace,
    JwtDecoded,
} from "../../Types/atoms";
import {
    ErrorResponse,
    IPlatformInfo,
    IRtmpInfo,
    IUserInfo,
    IWorkspace,
} from "../../Types/Common";
import {ELiveTypeValue, LiveStreamStatus} from "../../DjiBridge/live-stream";
import {AxiosError} from "axios/index";
import {Badge, Button, Col, Row} from "reactstrap";
import {
    bindGatewayApi,
    bindUavApi,
    getDeviceApi,
    getRtmpInfoApi,
    getWorkspaceApi,
    platformInfoApi,
    UserInfoApi,
} from "../../helpers/sarlink_backed";

const PilotLogin: React.FC = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [isBridgeConnected, setIsBridgeConnected] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [version, setVersion] = useState<IDjiVersion>();
    const components = DjiBridge.init();
    //console.log(window)
    //const [thingState,setThingState] = useState<EStatusValue>(EStatusValue.DISCONNECT)
    const [thingState, setThingState] = useRecoilState(AtomDjiThingState);
    const [apiState, setApiState] = useRecoilState(AtomDjiApiState);
    const [liveState, setLiveState] = useRecoilState(AtomDjiLiveState);
    const [liveStatus, setLiveStatus] = useState<LiveStreamStatus | null>(null);
    const [wsState, setWsState] = useRecoilState(AtomDjiWsState);
    const [mapState, setMapState] = useRecoilState(AtomDjiMapState);
    const [tsaState, setTsaState] = useRecoilState(AtomDjiTsaState);
    const [mediaState, setMediaState] = useRecoilState(AtomDjiMediaState);
    const [waylineState, setWaylineState] = useRecoilState(AtomDjiWaylineState);
    const [gatewayState, setGatewayState] = useRecoilState(AtomGatewayState); // Assuming this is a boolean since the original code does not provide its definition
    const [jwtDecoded, setJwtDecoded] = useRecoilState(JwtDecoded);
    const [remoteControllerSN, setRemoteControllerSN] = useState<string | null>(
        null
    );
    const [airCraftSN, setAirCraftSN] = useState<string | null>(null);
    const [aircraftBind, setAircraftBind] = useRecoilState(AtomAircraftBindState);
    const [thingConnectParam, setThingConnectParam] = useState<ThingParam>(
        components.get(EComponentName.Thing)
    );
    const [mediaParam, setMediaParam] = useRecoilState(AtomMediaParam);
    const [workspace, setWorkspace] = useRecoilState(AtomWorkspace);

    useEffect(() => {
        if (!thingConnectParam) return;
        djiBridge.setComponentParam(EComponentName.Thing, thingConnectParam);
    }, [thingConnectParam]);

    const connectApi = () => {
        const apiParam: ApiParam = components.get(EComponentName.Api);
        apiParam.host = API_URL + "api/v1/dji/";
        /*if (djiBridge.isComponentLoaded(EComponentName.Api)) {
                if (apiParam.token !== 'Bearer ' + getLocalAccessToken()) {
                    //console.log("unload api, token changed")
                    djiBridge.unloadComponent(EComponentName.Api)
                }
            } else {
                //console.log("api not loaded")
            }
    */
        apiParam.token = "Bearer " + getLocalAccessToken();
        djiBridge.setToken(apiParam.token);
        components.set(EComponentName.Api, apiParam);

        djiBridge.loadComponent(
            EComponentName.Api,
            components.get(EComponentName.Api)
        );
    };

    const connectThing = (unload = false) => {
        //console.log("connectThing", unload, DjiBridge.isComponentLoaded(EComponentName.Thing))
        // if (unload && DjiBridge.isComponentLoaded(EComponentName.Thing)) {
        //     console.log("unload thing in connectthing", DjiBridge.unloadComponent(EComponentName.Thing))
        //
        // }
        if (
            DjiBridge.isComponentLoaded(EComponentName.Thing) &&
            thingState === EStatusValue.CONNECTED
        ) {
            console.error("thing already connected");

            return;
        }
        console.log("connectThing remoteControllerSN", remoteControllerSN);

        if (!remoteControllerSN) return;
        platformInfoApi(remoteControllerSN)
            .then((response: IPlatformInfo) => {
                //console.log("DataService", "mqttInfo", response.data)
                thingConnectParam.username = response.username;
                thingConnectParam.password = response.password;
                thingConnectParam.host = response.host;
                thingConnectParam.connectCallback = "connectCallback";
                console.log("thingConnectParam", thingConnectParam);
                setThingConnectParam({...thingConnectParam});
                //console.log("thing config useEffect thingConnectParam:", thingConnectParam)
                components.set(EComponentName.Thing, thingConnectParam);
                //console.log(`DjiBridge.thingGetConfigs()`, DjiBridge.thingGetConfigs())
                const load_thing = djiBridge.loadComponent(
                    EComponentName.Thing,
                    thingConnectParam
                );
                console.log("load_thing", load_thing);
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (error.response) {
                    console.error(error);
                    toast.error("MQTT info not found");
                }
            });
    };
    const loadLiveStream = (airCraftSN) => {
        getRtmpInfoApi(airCraftSN)
            .then((response_rtmp: IRtmpInfo) => {
                const liveshareParam: LiveshareParam = components.get(
                    EComponentName.Liveshare
                );
                liveshareParam.videoPublishType = "video-demand-aux-manual";
                liveshareParam.statusCallback = "liveshareStatusCallback";
                djiBridge.setComponentParam(
                    EComponentName.Liveshare,
                    liveshareParam
                );

                djiBridge.loadComponent(EComponentName.Liveshare, liveshareParam);

                const rtmpParam = {
                    url: response_rtmp.rtmpUrl,
                };
                const rtcParam = {
                    url: response_rtmp.rtcUrl,
                };
                djiBridge.setLiveshareConfig(
                    ELiveTypeValue.RTMP,
                    JSON.stringify(rtmpParam)
                );
                // djiBridge.setLiveshareConfig(
                //     5,
                //     JSON.stringify(rtcParam)
                // );
                djiBridge.setLiveshareStatusCallback("liveshareStatusCallback");
                console.log("liveshareconfig:", djiBridge.getLiveshareConfig());
                components.set(EComponentName.Liveshare, liveshareParam);

            })
            .catch((error: AxiosError<ErrorResponse>) => {
                toast.error("RTMP info not found");
            });


    }
    useEffect(() => {
        if (!airCraftSN) return;
        //console.log("airCraftSN", airCraftSN)
        localStorage.setItem(storageAircraftSN, airCraftSN);

        bindUavApi(airCraftSN, remoteControllerSN as string)
            .then(() => {
                setAircraftBind(true);

                loadLiveStream(airCraftSN)
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                toast.error("Aircraft bind error");
            });
    }, [airCraftSN]);
    const refreshStatus = () => {
        if (!DjiBridge.thingGetConnectState()) {
            console.log("thing not connected");
            //console.log("connect",window.djiBridge.thingConnect("pilot1", "pilot123", "connectCallback1"))
            connectThing(true);
            //console.log("thingconfig", DjiBridge.thingGetConfigs())
        }
        if (!airCraftSN) {
            const sn = DjiBridge.getAircraftSN();
            if (sn) {
                setAirCraftSN(sn);
            } else {
                if (localStorage.getItem(storageAircraftSN)) {
                    setAirCraftSN(localStorage.getItem(storageAircraftSN) as string);
                }
            }
        }
        connectApi();

        setThingState(
            DjiBridge.thingGetConnectState()
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setApiState(
            DjiBridge.isComponentLoaded(EComponentName.Api)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setLiveState(
            DjiBridge.isComponentLoaded(EComponentName.Liveshare)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        //console.log(djiBridge.getLiveshareStatus())
        setLiveStatus(djiBridge.getLiveshareStatus());
        setWsState(
            DjiBridge.isComponentLoaded(EComponentName.Ws) &&
            DjiBridge.wsGetConnectState()
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setMapState(
            DjiBridge.isComponentLoaded(EComponentName.Map)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setTsaState(
            DjiBridge.isComponentLoaded(EComponentName.Tsa)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setMediaState(
            DjiBridge.isComponentLoaded(EComponentName.Media)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        setWaylineState(
            DjiBridge.isComponentLoaded(EComponentName.Mission)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );
        if (!DjiBridge.isComponentLoaded(EComponentName.Media)) {
            const load_media = DjiBridge.loadComponent(
                EComponentName.Media,
                mediaParam
            );
            console.log("loadComponent", load_media);
        }
        setMediaState(
            DjiBridge.isComponentLoaded(EComponentName.Media)
                ? EStatusValue.CONNECTED
                : EStatusValue.DISCONNECT
        );

        /* for (const value of Object.values(EComponentName)) {
                 //console.log(`loadComponent ${value} is_loaded:${DjiBridge.isComponentLoaded(value)}`)
             }*/
    };

    const connectCallback = async (arg: any) => {
        console.log("connectCallback", arg);

        if (arg) {
            toast.success("Server connected");
            setThingState(EStatusValue.CONNECTED);

            // ws
            const wsParam: WsParam = components.get(EComponentName.Ws);
            wsParam.host = WEBSOCKET_URL;
            wsParam.token = "" + getLocalAccessToken();
            wsParam.connectCallback = "wsConnectCallback";
            console.log(wsParam);
            djiBridge.setComponentParam(EComponentName.Ws, wsParam);
            djiBridge.loadComponent(
                EComponentName.Ws,
                components.get(EComponentName.Ws)
            );

            djiBridge.setDownloadOwner(EDownloadOwner.Mine.valueOf());

            const mapParam: MapParam = components.get(EComponentName.Map);
            mapParam.userName = "username.value";
            djiBridge.setComponentParam(EComponentName.Map, mapParam);
            djiBridge.loadComponent(
                EComponentName.Map,
                components.get(EComponentName.Map)
            );

            djiBridge.loadComponent(EComponentName.Tsa, {});
            //djiBridge.loadComponent(EComponentName.Mission, {})
        } else {
            setThingState(EStatusValue.DISCONNECT);
        }
    };
    const toggleautoUploadType = () => {
        // 👇️ passed function to setState
        let t = EPhotoType.Unknown;
        switch (mediaParam.autoUploadPhotoType) {
            case 1:
                t = EPhotoType.Original;
                break;
            case 0:
                t = EPhotoType.Preview;
                break;
        }
        setMediaParam({...mediaParam, autoUploadPhotoType: t});
    };
    const toggleautoUploadPhoto = () => {
        // 👇️ passed function to setState
        setMediaParam({
            ...mediaParam,
            autoUploadPhoto: !mediaParam.autoUploadPhoto,
        });
    };
    const toggleautoUploadVideo = () => {
        // 👇️ passed function to setState
        setMediaParam({
            ...mediaParam,
            autoUploadVideo: !mediaParam.autoUploadVideo,
        });
    };
    useEffect(() => {
        //console.log("mediaParam", mediaParam)
        if (!mediaParam) return;
        if (!isBridgeConnected) return;
        localStorage.setItem(storageMediaParam, JSON.stringify(mediaParam));
        djiBridge.setAutoUploadVideo(mediaParam.autoUploadVideo);
        djiBridge.setAutoUploadPhoto(mediaParam.autoUploadPhoto);
        djiBridge.setDownloadOwner(EDownloadOwner.Mine.valueOf());
        djiBridge.setUploadPhotoType(mediaParam.autoUploadPhotoType);
        //console.log(localStorage.getItem(storageMediaParam) ? JSON.parse(localStorage.getItem(storageMediaParam) as string) : "components.get(EComponentName.Media)")
    }, [mediaParam, isBridgeConnected]);
    const wsConnectCallback = async (arg: any) => {
        //console.log("wsConnectCallback", arg)
        //toast.info(`wsConnectCallback ${JSON.stringify(arg)}`)
    };

    useEffect(() => {
        if (errors.length > 0) {
            console.error(errors);
            errors.forEach((error) => {
                toast.error(error);
            });
        }
    }, [errors]);
    useEffect(() => {
        if (!isBridgeConnected) return;
    }, [isBridgeConnected]);
    const getUserInfo = async () => {
        await UserInfoApi()
            .then(async (response: IUserInfo) => {
                console.log("userinfo", response);
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (window.location.pathname !== "/login") {
                            toast.error("Credentials are not valid.");

                            window.location.href = "/login";
                        }
                    } else {
                        toast.error("An unexpected error occurred.");
                    }
                }
            });
    };
    useEffect(() => {
        getUserInfo();
        console.log("ELiveTypeValue.RTMP", ELiveTypeValue.RTMP);


        window.connectCallback = (arg: any) => {
            connectCallback(arg);
        };
        window.wsConnectCallback = (arg: any) => {
            wsConnectCallback(arg);
        };
        window.liveshareStatusCallback = (arg: any) => {
            //console.log("liveshareStatusCallback", arg)
        };
        if (isBridgeConnected) return;
        console.log("onBackClickReg");
        try {
            djiBridge.onBackClickReg();
            djiBridge.onStopPlatform();
        } catch (e) {
            console.error(e);
        }

        const errors: string[] = [];
        if (djiAppId === undefined) {
            errors.push("VITE_DJIAPPID not set");
        }
        if (djiAppKey === undefined) {
            errors.push("VITE_DJIAPPKEY not set");
        }
        if (djiAppLicense === undefined) {
            errors.push("VITE_DJIAPPKEY not set");
        }
        setErrors(errors);

        if (errors.length > 0) {
            return;
        }

        if (window.djiBridge !== undefined) {
            //console.log(window.djiBridge)
            setIsBridgeConnected(true);
            //console.log(`djiBridge is connected`, {toastId: "djiBridgeConnected"})

            verifyLicense();
            setRemoteControllerSN(DjiBridge.getRemoteControllerSN());
            //
        } else {
            //console.log("djiBridge not connected")
            toast.error(
                "djiBridge not connected. Please check your DJI app is installed and running"
            );
        }
    }, []);

    useEffect(() => {
        //console.log(`useEffect isVerified ${isVerified}`)
        if (!isVerified) return;
        //console.log("license verified")
        toast.success("License verified");

        setVersion(DjiBridge.getVersion());
        connectApi();

        getWorkspaceApi().then((response: IWorkspace[]) => {
            let data: IWorkspace;

            if (workspace === null) {
                data = response.filter((workspace: IWorkspace) => workspace.default)[0];
            } else {
                console.log(
                    "workspace",
                    workspace,
                    response.filter(
                        (workspace: IWorkspace) => workspace.id === workspace.id
                    )
                );

                data = response.filter(
                    (filter_workspace: IWorkspace) => filter_workspace.id === workspace.id
                )[0];
            }
            console.log("DataService", "workspace", data);

            setWorkspace(data);
        });

        const intervalCall = setInterval(() => {
            refreshStatus();
        }, 5000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [isVerified]);
    useEffect(() => {
        if (!workspace) return;
        //console.log("setworkspace", data)
        djiBridge.setWorkspaceId(workspace.id);
        djiBridge.setPlatformMessage(
            `${navBarTitle} ${workspace.full_name}`,
            workspace.full_name,
            APP_URL
        );
    }, [workspace]);
    const verifyLicense = () => {
        const verify: boolean =
            DjiBridge.platformVerifyLicense(djiAppId, djiAppKey, djiAppLicense) &&
            DjiBridge.isPlatformVerifySuccess();

        //console.log(verify)

        setIsVerified(verify);

        return verify;
    };
    const unloadThing = () => {
        //console.log(DjiBridge.isComponentLoaded(EComponentName.Thing))
        //console.log(DjiBridge.unloadComponent(EComponentName.Thing))
        refreshStatus();
    };

    useEffect(() => {
        if (!remoteControllerSN) return;
        getDeviceApi(remoteControllerSN)
            .then(() => {
                //console.log("DataService", "getGateway", response.data)
                setGatewayState(true);
            })
            .catch(async (error: AxiosError<ErrorResponse>) => {
                console.log("DataService", "getGateway", error, error.response);

                if (error.response) {
                    if (error.response.status === 404) {
                        setGatewayState(false);
                    }
                    bindGatewayApi(remoteControllerSN)
                        .then(() => {
                            setGatewayState(true);
                        })
                        .catch((error: AxiosError<ErrorResponse>) => {
                            toast.error("Gateway bind error");
                        });
                }
            });
        if (thingState === EStatusValue.DISCONNECT) {
            console.log("connecting thing first time");

            connectThing();
        } else {
            console.log("thing already connected");
        }
    }, [remoteControllerSN]);

    function getNameFromEnumValue(value: number): string | undefined {
        const entries = Object.entries(EPhotoType);
        for (const [key, val] of entries) {
            if (val === value) {
                return key;
            }
        }
        return undefined; // Or any other default value you prefer
    }

    return (
        <div className="page-content">
            <Row>
                <Col xs={10}>
                    {/*<p>licence:{isVerified.toString()}</p>*/}
                    {/*<p>bridge:{isBridgeConnected.toString()}</p>*/}
                    <p>
                        <Badge color={gatewayState ? "success" : "danger"}>
                            RC bind <small>SN:{remoteControllerSN}</small>
                        </Badge>
                        <br/>
                        <Badge color={aircraftBind ? "success" : "danger"}>
                            Aricraft bind <small>SN:{airCraftSN}</small>
                        </Badge>
                    </p>

                    <h5>Settings:</h5>
                    <p>
                        <Button
                            className={"mb-2"}
                            size={"sm"}
                            color={mediaParam.autoUploadPhoto ? "success" : "primary"}
                            onClick={toggleautoUploadPhoto}
                        >
                            {mediaParam.autoUploadPhoto ? "Automatic" : "Manual"} photo upload
                        </Button>{" "}
                        <br/>
                        <Button
                            className={"mb-2"}
                            size={"sm"}
                            color={mediaParam.autoUploadVideo ? "success" : "primary"}
                            onClick={() => toggleautoUploadVideo()}
                        >
                            {mediaParam.autoUploadVideo ? "Automatic" : "Manual"} video upload
                        </Button>{" "}
                        <br/>
                        <Button
                            className={"mb-2"}
                            size={"sm"}
                            color={"secondary"}
                            onClick={() => toggleautoUploadType()}
                        >
                            type:{getNameFromEnumValue(mediaParam.autoUploadPhotoType)}
                        </Button>{" "}
                        <br/>
                    </p>
                    <h5>Liveshare:</h5>
                    <Button
                        outline={true}
                        className={"mb-2"}
                        size={"sm"}
                        color={"success"}
                        onClick={djiBridge.startLiveshare}
                    >
                        Start Liveshare
                    </Button>
                    <br/>
                    <Button
                        outline={true}
                        className={"mb-2"}
                        size={"sm"}
                        color={"danger"}
                        onClick={() => djiBridge.stopLiveshare()}
                    >
                        Stop Liveshare
                    </Button>

                    {/*<code>{JSON.stringify(mediaParam)}</code>*/}
                </Col>
                <Col xs={2}>
                    <h4>Service Status:</h4>

                    <Badge
                        color={thingState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        IOT Service
                    </Badge>
                    <Badge
                        color={apiState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        API Service
                    </Badge>
                    <Badge
                        color={liveState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        Live Service
                    </Badge>
                    <Badge
                        color={wsState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        WebSocket
                    </Badge>
                    <Badge
                        color={mapState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        MAP Service
                    </Badge>
                    <Badge
                        color={tsaState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        TSA Service
                    </Badge>
                    <Badge
                        color={mediaState === EStatusValue.CONNECTED ? "success" : "danger"}
                    >
                        Media Service
                    </Badge>
                    {/*<Badge color={waylineState === EStatusValue.CONNECTED ? "success" : "danger"}>Wayline*/}
                    {/*    Service</Badge>*/}
                </Col>
            </Row>
        </div>
    );
};

export default PilotLogin;
