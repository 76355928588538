import {Libraries} from "@react-google-maps/api";

const env = {};
export const storageAccessToken = "access_token";
export const storageRefreshToken = "refresh_token";
export const storageMediaParam = "MediaParam";
export const storageAircraftSN = "AircraftSN";
//export const navBarTitle = env.NAV_BAR_TITLE!==undefined ? env.NAV_BAR_TITLE : "NAV_BAR_TITLE";
//export const API_URL = env.API_URL!==undefined ? env.API_URL : "http://localhost:8000/";
export const API_URL = import.meta.env.VITE_API_URL
    ? import.meta.env.VITE_API_URL
    : "http://localhost:3000/";
export const APP_URL = import.meta.env.VITE_APP_URL
    ? import.meta.env.VITE_APP_URL
    : "http://localhost:3000/";
export const MQTT_URL = import.meta.env.VITE_MQTT_URL
    ? import.meta.env.VITE_MQTT_URL
    : "wss://sarmqtt.ngrok.dev/mqtt";
export const MQTT_TCP = import.meta.env.VITE_MQTT_TCP
    ? import.meta.env.VITE_MQTT_TCP
    : "tcp://nomad.lan:1883";
export const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
    ? import.meta.env.VITE_WEBSOCKET_URL
    : "wss://sarws.ngrok.dev";

export const djiAppId = import.meta.env.VITE_DJIAPPID
    ? import.meta.env.VITE_DJIAPPID
    : ""; // You need to go to the development website to apply.
export const djiAppKey = import.meta.env.VITE_DJIAPPKEY
    ? import.meta.env.VITE_DJIAPPKEY
    : ""; // You need to go to the development website to apply.
export const djiAppLicense = import.meta.env.VITE_DJIAPPLICENSE
    ? import.meta.env.VITE_DJIAPPLICENSE
    : "";
export const GoogleApiKey = import.meta.env.VITE_GOOGLE_API_KEY
    ? import.meta.env.VITE_GOOGLE_API_KEY
    : "";
export const MapboxApiKey = import.meta.env.VITE_MAPBOX_API_KEY
    ? import.meta.env.VITE_MAPBOX_API_KEY
    : "";
export const DisableVConsole = import.meta.env.VITE_DISABLE_VCONSOLE == "true";
export const SENTRY_DNS = import.meta.env.VITE_SENTRY_DSN
    ? import.meta.env.VITE_SENTRY_DSN
    : undefined;

export enum BRAND_NAMES {
    SARDRONE = "sardrone",
    QNTROL = "qntrol",
    DRONEVID = "dronevid",
}

const settings = {
    sardrone: {
        title: "SAR Drone",
    },
    dronvid: {
        title: "DRON Vid",
    },
    qntrol: {
        title: "DRONEQAST",
        company: "Qntrol",
    },
};

export let BRAND_NAME = import.meta.env.VITE_BRAND_NAME as BRAND_NAMES;
if (BRAND_NAME === undefined) {
    BRAND_NAME = BRAND_NAMES.SARDRONE;
}
export let navBarTitle = import.meta.env.VITE_NAV_BAR_TITLE
    ? import.meta.env.VITE_NAV_BAR_TITLE
    : settings[BRAND_NAME].title;
// if (import.meta.env.NODE_ENV !== "production") {
//   console.log("Running in", import.meta.env.NODE_ENV, "mode")
//   navBarTitle = navBarTitle + ` ${import.meta.env.NODE_ENV}`;
// }
export let navBarCompany = settings[BRAND_NAME].company
    ? settings[BRAND_NAME].company
    : "Kait d.o.o.";

export const LOGO_BIG_PATH = `/assets/logo-light-${BRAND_NAME}-big.svg`;
export const LOGO_BIG_PATH_PNG = `/assets/logo-light-${BRAND_NAME}-big.png`;
export const LOGO_SMALL_PATH = `/assets/logo-light-small-${BRAND_NAME}.png`;

export enum StreamTabIndex {
    ONLINE = "online",
    OFFLINE = "offline",
    ALL = "all",
    NOT_SET = "",
}

export const DefaultMarkerIcon: google.maps.Symbol = {
    path: "M12 21L19 12L12 3L5 12L12 21Z",
    strokeColor: "#FF0000",
    fillOpacity: 0,
    strokeWeight: 2,
};
export const DefaultMarkerEditIcon: google.maps.Symbol = {
    path: "M9 4H7c-1.414 0-2.121 0-2.56.44C4 4.878 4 5.585 4 7v2m5 11H7c-1.414 0-2.121 0-2.56-.44C4 19.122 4 18.415 4 17v-2M15 4h2c1.414 0 2.121 0 2.56.44C20 4.878 20 5.585 20 7v2m-5 11h2c1.414 0 2.121 0 2.56-.44.44-.439.44-1.146.44-2.56v-2",
    strokeColor: "#FF0000",
    fillOpacity: 0,
    strokeWeight: 2,
};

export enum MapElementColors {
    BLUE = "#2D8CF0",
    GREEN = "#19BE6B",
    YELLOW = "#FFBB00",
    PURPLE = "#B620E0",
    RED = "#E23C39",
    BLACK = "#212121",
}

export const defaultLibraries: Libraries = ["maps", "drawing", "places"];
