import React, { useEffect } from "react"
import { Spinner } from "reactstrap";
import spinnerGif from 'assets/spinner.gif'; // Adjust the path accordingly

const Spinners = ({ setLoading }: any) => {

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [setLoading]);
    
    return (
        <React.Fragment>
            <Spinner color="primary" className='position-absolute top-50 start-50' />
            {/*<img src={spinnerGif} className='position-absolute top-50 start-50' alt="Loading..." />*/}

        </React.Fragment>
    )
}

export default Spinners;