import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";


import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import translationSI from "./locales/sl/translation.json";

// the translations
const resources: any = {

    de: {
        translation: translationDE,
    },
    en: {
        translation: translationEN,
    },
    sl: {
        translation: translationSI,
    },
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: localStorage.getItem("I18N_LANGUAGE") || "en",
        fallbackLng: "en", // use en if detected lng is not available
        debug: !import.meta.env.PROD,
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;