import {Alert, Form, FormFeedback, Input, Label, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {LoginKeyApi} from "../../helpers/sarlink_backed";
import {ErrorResponse, ILogin} from "../../Types/Common";
import {clearLocalTokens, setLocalAccessToken, setLocalRefreshToken} from "../../helpers/api_helper";
import {toast} from "react-toastify";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AxiosError,} from "axios/index";
import {Browser} from "leaflet";
import win = Browser.win;

const LoginPilot = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();

    const [loginKey, setLoginKey] = useState<string | null>(null);
    useEffect(() => {

        const query = new URLSearchParams(location.search);
        const sesame = query.get('sesame'); // Assuming you have a parameter named 'id'

        if (sesame) {
            setLoginKey(sesame)
            console.log(sesame)
            doLoginSesame({key: sesame});

        }

    }, [location.search]);


    const doLoginSesame = (user_data: any,): void => {
        //event.preventDefault();
        console.log("doLoginSesame()")
        setIsLoading(true);
        LoginKeyApi({key: user_data.key})
            .then((response: ILogin) => {
                clearLocalTokens();

                setLocalAccessToken(response.access);
                setLocalRefreshToken(response.refresh);
                //setAccess_token(data.access);
                //setRefresh_token(data.refresh);
                if (window.djiBridge !== undefined) {
                    navigate("/pilot-login")
                } else {
                    navigate("/")
                }
                //window.location.href = '/';
            }).catch((error: AxiosError<ErrorResponse>) => {
            if (error.response && error.response.data) {
                const errors: ErrorResponse = error.response.data;
                toast.error("Credentials are not valid.");

            } else {
                // Handle other types of errors, if needed
                console.log("error", error)
                toast.error("An unexpected error occurred.");
            }
        }).finally(() => {
            setIsLoading(false)
        });

    }

    const validationSesame: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            key: '',
        },
        validationSchema: Yup.object({
            key: Yup.string().required("Please Enter Your key, generated in dashboard"),
        }),
        onSubmit: (values: any) => {
            //doLoginSesame(values, props.router.navigate);
            doLoginSesame(values);
        }
    });

    const selectProperties = createSelector(
        (state: any) => state.Login,
        (error) => error
    );

    const {error} = useSelector(selectProperties);

    return (
        <Form className="form-horizontal"
              onSubmit={(e) => {
                  e.preventDefault();
                  validationSesame.handleSubmit();
                  return false;
              }}
        >
            <div className="mb-3">
                {error ? <Alert color="danger">{error}</Alert> : null}
                <Label className="form-label">Key</Label>
                <Input
                    name="key"
                    className="form-control"
                    placeholder="Enter login key"
                    type="text"
                    onChange={validationSesame.handleChange}
                    onBlur={validationSesame.handleBlur}
                    value={validationSesame.values.key || loginKey}
                    invalid={
                        validationSesame.touched.key && validationSesame.errors.key ? true : false
                    }
                />
                {validationSesame.touched.key ? (
                    <FormFeedback
                        type="invalid">{validationSesame.errors.key}</FormFeedback>
                ) : null}
            </div>


            <div className="mt-3 d-grid">
                {isLoading ? (<Spinner></Spinner>) : (<button
                    className="btn btn-primary btn-block "
                    type="submit"
                >
                    Log In
                </button>)}
            </div>


        </Form>
    )
}
export default LoginPilot