import React, { memo, useCallback, useEffect, useState } from "react";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { defaultLibraries, GoogleApiKey, WEBSOCKET_URL } from "../../constants";
import MapGoogleProvider from "./MapProvider/Google";
import { ILocation } from "./MapProvider/Types";
import { IMapElement, IMapElementGroup, IWorkspace } from "../../Types/Common";
import { toast } from "react-toastify";

import { useRecoilState, useRecoilValue } from "recoil";
import { FaDeleteLeft, FaMagnifyingGlass } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import {
  ActiveMapElement,
  AtomMapGroup,
  AtomMapFitBounds,
  AtomPlayingStreams,
  AtomWorkspace,
  EditMapElement,
  AtomAditionalMarkers,
} from "../../Types/atoms";
import { MdDeleteForever } from "react-icons/md";
import { AxiosResponse } from "axios/index";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

import IconFirefighter from "../../assets/firefighter-svgrepo-com.svg";
import { useMapElements } from "../../Providers/MapElementsProvider";
import { mapElementDeleteApi } from "../../helpers/sarlink_backed";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";

type LocationError = {
  code: number;
  message: string;
};

const Map: React.FC = () => {
  const [center_location, setCenter_location] = useState<ILocation>({
    latitude: 0,
    longitude: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const { mapElements, setMapElements } = useMapElements();
  const [mapGroup, setMapGroup] = useRecoilState(AtomMapGroup);
  const { mapElementGroups, setMapElementGroups } = useMapElements();
  //const [mapElementGroups, setMapElementGroups] = useState<IMapElementGroup[]>([]);
  const [activeElement, setActiveElement] = useRecoilState(ActiveMapElement);
  const [editElement, setEditElement] = useRecoilState(EditMapElement);
  const [markerBounds, setMarkerBounds] =
    useState<google.maps.LatLngBounds | null>(null);
  const [additionalMarkers, setAdditionalMarkers] =
    useRecoilState(AtomAditionalMarkers);

  const [zoom, setZoom] = useState(10);
  const [mapGroupDropdown, setMapGroupDropdown] = useState(false);
  const {t,i18n} = useTranslation();
  const googleMapsLoader = useJsApiLoader({
    id: `map-${i18n.language}`,
    language: i18n.language,
    region: i18n.language,
    googleMapsApiKey: GoogleApiKey,
    libraries: defaultLibraries,
    //libraries:["drawing"]
  });


  useEffect(() => {
    if (!mapElements) return;
    //console.log(googleMapsLoader.isLoaded)
    if (!googleMapsLoader.isLoaded) return;
    console.log("mapElements changed", mapElements);
    const bounds = new google.maps.LatLngBounds();
    mapElements.forEach((mapElement: IMapElement) => {
      bounds.extend({
        lat: mapElement.center.latitude,
        lng: mapElement.center.longitude,
      });
    });
    setMarkerBounds(bounds);
  }, [mapElements, googleMapsLoader.isLoaded]);

  // useEffect(() => {
  //     mapProps.center_location = center_location;
  // }, [center_location]);
  //mapProps.map = map;

  const removeElement = async (mapElement: IMapElement) => {
    setMapElements(
      mapElements.filter((item: IMapElement) => item.id !== mapElement.id)
    );
  };
  const zoomElement = async (mapElement: IMapElement, force = false) => {
    if (mapElement.id === activeElement?.id && !force) return;
    setCenter_location({
      latitude: mapElement.center.latitude,
      longitude: mapElement.center.longitude,
    });
    const b = {
      east: mapElement.bounds[2],
      north: mapElement.bounds[3],
      south: mapElement.bounds[1],
      west: mapElement.bounds[0],
    };
    setZoom(18);
    setMarkerBounds(new google.maps.LatLngBounds(b));
    setActiveElement(mapElement);
  };
  const setEdit1 = useCallback((mapElement: IMapElement) => {
    if (editElement) {
      setEditElement(undefined);
    } else {
      setEditElement(mapElement);
    }
  }, []);
  const setEdit = (mapElement: IMapElement) => {
    if (editElement) {
      setEditElement(undefined);
    } else {
      setEditElement(mapElement);
    }
  };

  const remove = (mapElement: IMapElement) => {
    mapElementDeleteApi(mapElement)
      .then(() => {
        removeElement(mapElement);
      })
      .catch((err: unknown) => {
        toast.error(t("Error deleting map element"));
      });
  };
  const selectGroup = (group_id: string) => {
    console.log("group_id", group_id);
    //console.log("mapElementGroups", mapElementGroups)
    const group = mapElementGroups.find(
      (item: IMapElementGroup) => item.id === group_id
    );
    if (group) {
      setMapGroup(group);
      console.log("setMapGroup", group);
    }
    if (group === undefined) {
      window.location.reload();
    }
  };

  return (
    <div className="page-content">
      <Row className={"vh-100"}>
        <Col sm={4} lg={2}>
          <Dropdown
            className="d-inline-block "
            color={"light"}
            isOpen={mapGroupDropdown}
            toggle={() => setMapGroupDropdown(!mapGroupDropdown)}
          >
            <DropdownToggle className="btn" caret>
              Map Group: {mapGroup?.name}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu>
              {mapElementGroups.map(
                (mapElementGroup: IMapElementGroup, index) => {
                  return (
                    <DropdownItem
                      key={mapElementGroup.id}
                      href="#"
                      onClick={() => selectGroup(mapElementGroup.id)}
                    >
                      {mapElementGroup.name}
                    </DropdownItem>
                  );
                }
              )}
            </DropdownMenu>
          </Dropdown>

          {mapElements.length === 0 && (
            <Alert color={"warning"}>No map elements</Alert>
          )}
          {
            <div>
              <ul
                className="list-unstyled chat-list position-relative"
                id="recent-list"
              >
                {
                  <SimpleBar style={{ height: "100vh" }}>
                    {mapElements.map((mapElement: IMapElement, index) => (
                      <li
                        key={mapElement.id}
                        className={
                          mapElement.id === activeElement?.id
                            ? "map-element-active"
                            : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => zoomElement(mapElement, true)}
                        >
                          <div className="d-flex">
                            <div className="align-self-center me-3">
                              <i
                                style={{ color: mapElement.color }}
                                className={"mdi mdi-circle font-size-10"}
                              />
                            </div>

                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate font-size-14 mb-1">
                                {mapElement.name}
                              </h5>
                              <p className="text-truncate mb-0"></p>

                              {mapElement.id === activeElement?.id && (
                                <p className=" mb-0">
                                  <div>
                                    <FaMagnifyingGlass
                                      className={"me-3"}
                                      onClick={() => {
                                        zoomElement(mapElement);
                                      }}
                                    ></FaMagnifyingGlass>
                                    <FaEdit
                                      className={"me-3"}
                                      onClick={() => {
                                        setEdit(mapElement);
                                      }}
                                    ></FaEdit>
                                    <MdDeleteForever
                                      className={"me-3"}
                                      onClick={() => {
                                        remove(mapElement);
                                      }}
                                    ></MdDeleteForever>
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </SimpleBar>
                }
              </ul>
            </div>
          }
        </Col>
        <Col sm={8} lg={10} className={"h-100"}>
          <MapGoogleProvider
            mapElements={mapElements}
            center_location={center_location}
            zoom={zoom}
            markerBounds={markerBounds}
            additionalMarkers={additionalMarkers}
            isLoaded={googleMapsLoader.isLoaded}
          ></MapGoogleProvider>
        </Col>
      </Row>
    </div>
  );
};
export default memo(Map, isEqual);
