import React, { useEffect, useState } from "react";

import { StreamTabIndex } from "../../constants";
import { IStream } from "../../Types/Common";
import StreamCard from "./StreamCard";
import {
  Badge,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { StreamListApi } from "../../helpers/sarlink_backed";
import isEqual from "react-fast-compare";

const Stream: React.FC = () => {
  // Use TypeScript annotations for useState
  const [tabKey, setTabKey] = useState<StreamTabIndex>(StreamTabIndex.NOT_SET);

  const [streams, setStreams] = useState<IStream[]>([]);
  const [streamsOnline, setStreamsOnline] = useState<IStream[]>([]);
  const [streamsOffline, setStreamsOffline] = useState<IStream[]>([]);
  const [xlSize, setXlSize] = useState(3);
  const [lSize, setLSize] = useState(2);

  // const playAll = () => {
  //     //console.log(playingStreams)
  //     setPlayingStreams((prevItems) => [...streams.map((stream) => stream.id)]);
  // };
  // const stopAll = () => {
  //     setPlayingStreams([]);
  // };
  useEffect(() => {
    if (streamsOnline.length === 0 && streamsOffline.length === 0) return;
    if (tabKey === StreamTabIndex.NOT_SET) {
      //console.log("change tab current:" + tabKey, streamsOnline, streamsOffline)
      if (streamsOnline.length === 0) {
        //console.log("setkey ", StreamTabIndex.OFFLINE)
        setTabKey(StreamTabIndex.OFFLINE);
      } else {
        //console.log("setkey ", StreamTabIndex.ONLINE)

        setTabKey(StreamTabIndex.ONLINE);
      }
    }
  }, [streamsOnline, streamsOffline, tabKey]);

  const getStreams = async () => {
    await StreamListApi()
      .then(async (response: IStream[]) => {
        console.log("DataService", response);
        setStreams(response);

        const onlineStreams = response.filter((stream) => stream.is_live);
        const offlineStreams = response.filter((stream) => !stream.is_live);
        await setStreamsOnline(onlineStreams);
        await setStreamsOffline(offlineStreams);
      })
      .catch((err: unknown) => {
        console.error(err);

        return new Error("I crashed!");
      });
  };
  useEffect(() => {
    getStreams();

    const intervalCall = setInterval(() => {
      getStreams();
    }, 15000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const toggle = (tab: any) => {
    if (tabKey !== tab) {
      setTabKey(tab);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        {/*<Row>*/}
        {/*    <div className="d-flex flex-wrap gap-2">*/}

        {/*        <Button color="success" disabled={playingStreams.length===streams.length} outline onClick={playAll}>Play all </Button>*/}
        {/*        <Button color="primary" disabled={playingStreams.length===0} outline onClick={stopAll}>Stop all</Button>*/}

        {/*    </div>*/}
        {/*</Row>*/}

        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: tabKey === StreamTabIndex.ONLINE,
              })}
              onClick={() => {
                toggle(StreamTabIndex.ONLINE);
              }}
            >
              Online
              <Badge bg="primary">{streamsOnline.length}</Badge>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: tabKey === StreamTabIndex.OFFLINE,
              })}
              onClick={() => {
                toggle(StreamTabIndex.OFFLINE);
              }}
            >
              Offline
              <Badge bg="primary">{streamsOffline.length}</Badge>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: tabKey === StreamTabIndex.ALL,
              })}
              onClick={() => {
                toggle(StreamTabIndex.ALL);
              }}
            >
              ALL
              <Badge bg="primary">{streams.length}</Badge>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tabKey} className="p-3 text-muted">
          <TabPane tabId={StreamTabIndex.ONLINE}>
            <Row xl={xlSize} lg={lSize}>
              {streamsOnline.map((stream: IStream) => {
                return <StreamCard {...stream}></StreamCard>;
              })}
            </Row>
          </TabPane>
          <TabPane tabId={StreamTabIndex.OFFLINE}>
            <Row xl={xlSize} lg={lSize}>
              {streamsOffline.map((stream: IStream) => {
                return <StreamCard {...stream}></StreamCard>;
              })}
            </Row>
          </TabPane>
          <TabPane tabId={StreamTabIndex.ALL}>
            <Row xl={xlSize} lg={lSize}>
              {streams.map((stream: IStream) => {
                return <StreamCard {...stream}></StreamCard>;
              })}
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
};
export default React.memo(Stream, isEqual);
