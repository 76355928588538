import React from 'react';
import {PageErrorBoundaryProps} from './PageErrorBoundaryTypes';
import isEqual from 'react-fast-compare';
import {Alert, Container} from "reactstrap";

const PageErrorBoundary = ({error, resetErrorBoundary}: PageErrorBoundaryProps): JSX.Element => {
    return (
        <div className="page-content">
            <Container fluid>
                <Alert className={'small'} variant='danger'>
                    ERROR: {error?.message}
                </Alert>
            </Container></div>
    );
};
export default PageErrorBoundary
