import {APIClient} from "./api_helper";
import * as url from "./url_helper";
import {
    ICredentials, ICredentialsKey,
    IDevice,
    ILogout,
    IMapElement,
    IMapElementCreate,
    IPostDemo,
    IStreamStats,
    IUrl
} from "../Types/Common";
import {DEVICECAMERA} from "./url_helper";



const api = new APIClient();





// Register Method
export const postFakeRegister = (data: any) => {
    return api.create(url.POST_FAKE_REGISTER, data)
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message = "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);


// Register Method
export const postJwtRegister = (url: any, data: any) => {
    return api.create(url, data)
        .catch((err: any) => {
            var message: any;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message = "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);


// const getEventsApi1:IPostDemo[] = () => {
//     api.get(url.GET_DEMO_DATA, null)
//         .then((response: AxiosResponse<IPostDemo>) => {
//             console.log(response.data)
//             return response.data
//         }).catch((error) => {
//         console.log(error)
//         throw error
//     })
// }
const getEventsApi = () => api.get(url.GET_DEMO_DATA, null)

export const LoginApi = (credentials: ICredentials) => api.create(url.URL_LOGIN, credentials)
export const LoginKeyApi = (credentials: ICredentialsKey) => api.create(url.URL_KEY_LOGIN, credentials)
export const LogoutApi = (token: ILogout) => api.create(url.URL_LOGOUT, token)

export const UserInfoApi = () => api.get(url.USER_INFO, {})
export const StreamListApi = () => api.get(url.STREAM, {})
export const StreamRecoringsListApi = (id:string) => api.get(`${url.STREAM}${id}/recordings/`, {})
export const StreamGetApi = (id:string) => api.get(`${url.STREAM}${id}/`, {})
export const DeviceCameraStartLiveApi = (id:number) => api.create(`${url.DEVICECAMERA}${id}/start_live/`, {})
export const DeviceCameraStopLiveApi = (id:number) => api.create(`${url.DEVICECAMERA}${id}/stop_live/`, {})
export const DeviceCameraStopAllApi = (id:number) => api.create(`${url.DEVICECAMERA}${id}/stop_all/`, {})

export const getDeviceApi = (sn: string) => api.get(`${url.DEVICE}${sn}/`, {})
export const bindUavApi = (sn: string, gwsn: string) => api.create(url.DEVICE, {sn: sn, parent: gwsn})
export const getRtmpInfoApi = (sn: string) => api.get(`${url.RTMP_INFO}${sn}/`, {})
export const bindGatewayApi = (sn: string) => api.create(url.DEVICE, {sn: sn})
export const platformInfoApi = (sn: string) => api.get(`${url.PLATFORM_INFO}`, {gateway: sn})
export const getWorkspaceApi = () => api.get(`${url.WORKSPACE}`, {})

export const getMapElementsApi = (group_id: null | string) => api.get(`${url.MAP_ELEMENTS}`, {group_id: group_id})

export const getMapElementsGroupApi = (workspace_id: string) => api.get(`${url.MAP_ELEMENTS_GROUP}${workspace_id}/`, {})

export const mapElementCreateApi = (element: IMapElementCreate) => api.create(`${url.MAP_ELEMENTS}`, element)

export const mapElementDeleteApi = (element: IMapElement) => api.delete(`${url.MAP_ELEMENTS}${element.id}/`,{})
export const getDroneInfoApi = (id) => api.get(`${url.DRONEINFO}${id}/`, {});

export const getDroneApi = (sn: string) => api.get(`${url.DRONE}${sn}/`, {})
export const listDroneApi = () => api.get(`${url.DRONE}`, {})
export const updateDeviceApi = (id: number, data: any) => api.update(`${url.DRONE}${id}/`, data)
// export const getDroneLastLocationApi = (id: string) => api.get(`${url.DRONE}${id}/last_location/`, {})
// export const getDroneLastLocationRcApi = (id: string) => api.get(`${url.DRONE}${id}/last_location_rc/`, {})
// export const getDroneLastFlightApi = (id: string) => api.get(`${url.DRONE}${id}/last_flight/`, {})
// export const listDroneLastBatteryApi = (id: string) => api.get(`${url.DRONE}${id}/last_battery/`, {})
export const listDroneStreamsApi = (id: string) => api.get(`${url.DRONE}${id}/streams/`, {})
// export const getDroneLastPayloadApi = (id: string) => api.get(`${url.DRONE}${id}/last_payload/`, {})
// export const getRcApi = (id: string) => api.get(`${url.RC}${id}/`, {})
// export const listRcApi = () => api.get(`${url.RC}`, {})
export const listMediaApi = (page_number: number,page_size:number) => api.get(`${url.MEDIA}`, {page: page_number,page_size:page_size})
export const listSwitchCompanyApi = () => api.get(`${url.SWITCH_COMPANY}`, {})
export const activateCompanyApi = (id:number) => api.create(`${url.SWITCH_COMPANY}${id}/activate/`, {})
//    mapElement = (id: string) => http.get(`api/v1/map/elements/${id}/`) as Promise<IMapElement>;
// StreamSnapshot = (id: string) => http.get(`api/v1/stream/${id}/snapshot/`) as IUrl;
// StreamStats = (id: string) => http.get(`api/v1/stream/${id}/stats/`) as IStreamStats;
// StreamPlay = (id: string, offer_sdp: string) => {
//     const config: AxiosRequestConfig = {
//         headers: {
//             'Content-Type': 'application/sdp',
//         }
//     };
//     return http.post(`api/v1/play_rtc/${id}/`, offer_sdp, config) as string
// };
//
// unbindGateway = (sn: string) => http.delete(`api/v1/device/${sn}/`) as IDevice;
//

export {getEventsApi};
