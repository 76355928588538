import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Dron from "../pages/Dron/";
// Auth

import PilotLogin from "../pages/PilotLogin/PilotLogin";
import Stream from "../pages/Stream/Stream";
import PageErrorBoundary from "../pages/PageErrorBoundary/PageErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";
import Video from "../pages/Video/Video";
import Map from "../pages/Map/Map";
import Pages404 from "../pages/Utility/pages-404";
import Media from "../pages/Media";
import Login from "../pages/Authentication/login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Register from "../pages/Authentication/Register";
import Flights from "../pages/Flights";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  {
    path: "/stream",
    component: (
      <ErrorBoundary FallbackComponent={PageErrorBoundary}>
        {" "}
        <Stream />{" "}
      </ErrorBoundary>
    ),
  },
  { path: "/map", component: <Map /> },

  { path: "/video/:id", component: <Video /> },
  { path: "/pilot-login", component: <PilotLogin /> },
  {
    path: "/drone/:id",
    component: (
      <ErrorBoundary FallbackComponent={PageErrorBoundary}>
        <Dron />
      </ErrorBoundary>
    ),
  },
  { path: "/media", component: <Media /> },
  { path: "/flights", component: <Flights /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Pages404></Pages404> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
];
export { authProtectedRoutes, publicRoutes };
