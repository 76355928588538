import React, {useEffect, useRef, useCallback} from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import {Link} from "react-router-dom";

//i18n
import {useTranslation, withTranslation} from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import {useRecoilState, useRecoilValue} from "recoil";
import {AtomDrones} from "../../Types/atoms";
import {IDrone, IDroneGroup} from "../../Types/Dron";
import {groupByCompanyName} from "../../helpers";

const SidebarContent = (props: any) => {
    const ref = useRef<any>();
    const drones = useRecoilValue(AtomDrones);
    const groupedData: IDroneGroup = groupByCompanyName(drones);

    const {t} = useTranslation();
    const activateParentDropdown = useCallback((item: any) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const activeMenu = useCallback(() => {
        const pathName =
            import.meta.env.PUBLIC_URL + props.router.location.pathname;
        let matchingMenuItem = null;
        const ul: any = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);
        for (let i = 0; i < items.length; ++i) {
            if (pathName == items[i].pathname) {
                matchingMenuItem = items[i];

                break;
            }
        }
        console.log("matchingMenuItem", matchingMenuItem);
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [props.router.location.pathname, activateParentDropdown, drones]);

    useEffect(() => {
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
        activeMenu();
        // deps are empty because we don't want to run this effect on component update
    }, []);

    function scrollElement(item: any) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (
        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{t("Menu")} </li>
                        <li>
                            <Link to="/dashboard">
                                <i className="bx bx-home-circle"></i>
                                <span>{t("Dashboards")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/map">
                                <i className="bx bx-map-alt"></i>
                                <span>{t("Map")}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/drone/" className="has-arrow">
                                <i className="bx bxs-plane"></i>

                                <span>{t("Drones")}</span>
                                {drones.filter((value) => {
                                    return !value.is_online;
                                }).length > 0 && (
                                    <span
                                        className="badge rounded-pill text-bg-danger ms-1"
                                        key="t-new"
                                    >
                    {
                        drones.filter((value) => {
                            return !value.is_online;
                        }).length
                    }{" "}
                  </span>
                                )}

                                {drones.filter((value) => {
                                    return value.is_online;
                                }).length > 0 && (
                                    <span
                                        className="badge rounded-pill text-bg-success ms-1"
                                        key="t-new"
                                    >
                    {
                        drones.filter((value) => {
                            return value.is_online;
                        }).length
                    }{" "}
                  </span>
                                )}
                            </Link>

                            <ul className="sub-menu">
                                {drones.map((drone: IDrone) => (
                                    <li key={drone.id}>
                                        <Link to={`/drone/${drone.id}`}>
                                            {" "}
                                            {drone.is_online === true ? (
                                                <span
                                                    className="badge rounded-pill text-bg-success"
                                                    key="t-new"
                                                >
                          &nbsp;&nbsp;
                        </span>
                                            ) : (
                                                <span
                                                    className="badge rounded-pill text-bg-danger"
                                                    key="t-new"
                                                >
                          &nbsp;&nbsp;
                        </span>
                                            )}{" "}
                                            {drone.full_name}
                                            {Object.keys(groupedData).length > 1 && (
                                                <><br/><small>{drone.active_company_name}</small></>
                                            )}
                                    </Link>
                                    </li>
                                    ))}
                            </ul>
                        </li>
                        <li>
                            <Link to="/media">
                                <i className="bx bx-photo-album"></i>
                                <span>{t("Media")}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/stream">
                                <i className="bx bx-video"></i>
                                <span>{t("Live video")}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(SidebarContent));
