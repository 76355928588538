import React, {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";

import {ErrorResponse, Link} from "react-router-dom";

// Reactstrap
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Tooltip,
} from "reactstrap";

// Import menuDropdown
import NotificationDropDown from "../../Components/CommonForBoth/NotificationDropDown";
import ProfileMenu from "../../Components/CommonForBoth/TopBarDropDown/ProfileMenu";

// import images

import {LOGO_BIG_PATH, LOGO_SMALL_PATH} from "../../constants";

//i18n
import {useTranslation, withTranslation} from "react-i18next";
import {AxiosError} from "axios/index";
import {ISwitchCompany, IUserInfo, IWorkspace} from "../../Types/Common";
import {useRecoilState} from "recoil";
import {
    AtomDrones,
    AtomWorkspace,
    IsAuth,
    JwtDecoded,
    SwitchCompany,
    UserInfo,
} from "../../Types/atoms";
import {toast} from "react-toastify";
import {ReadyState} from "react-use-websocket";
import {useSharedWebSocket} from "../../Providers/WebsocketProvider";
import {
    getLocalAccessToken,
    getLocalRefreshToken,
    setLocalAccessToken,
} from "../../helpers/api_helper";
import {
    getWorkspaceApi,
    listDroneApi,
    listSwitchCompanyApi,
    UserInfoApi,
} from "../../helpers/sarlink_backed";
import {IDrone} from "../../Types/Dron";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import * as Sentry from "@sentry/react";

const Header = (props: any) => {
    const [isAuth, setIsAuth] = useRecoilState(IsAuth);
    const [jwtDecoded, setJwtDecoded] = useRecoilState(JwtDecoded);
    const [singlebtn, setSinglebtn] = useState(false);
    const [drones, setDrones] = useRecoilState(AtomDrones);
    const [switchCompany, setSwitchCompany] = useRecoilState(SwitchCompany);
    const [userInfo, setUserInfo] = useRecoilState(UserInfo);
    const [workspace, setWorkspace] = useRecoilState(AtomWorkspace);
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
    const {sendMessage, lastMessage, readyState} = useSharedWebSocket();
    const [tbottom, settbottom] = useState<boolean>(false);
    const {t} = useTranslation()
    const connectionIcon = {
        [ReadyState.CONNECTING]: "mdi-lan-pending",
        [ReadyState.OPEN]: "mdi-lan-connect",
        [ReadyState.CLOSING]: "mdi-lan-disconnect",
        [ReadyState.CLOSED]: "mdi-lan-disconnect",
        [ReadyState.UNINSTANTIATED]: "mdi-connection",
    }[readyState];
    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Connected",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];
    useEffect(() => {
        if (userInfo) {
            Sentry.setUser({
                fullName: `${userInfo.first_name} ${userInfo.last_name} (${userInfo.company.slug})`,
                email: userInfo.email,
            });
        }
    }, [userInfo]);
    const getRequiredData = async () => {
        await listDroneApi()
            .then(async (response: IDrone[]) => {
                setDrones(response);
            })
            .catch((error: AxiosError<ErrorResponse>) => {
            });
        await UserInfoApi()
            .then(async (response: IUserInfo) => {
                //console.log("DataService", "streams")
                setUserInfo(response);
                getWorkspaceApi()
                    .then((workspace_response: IWorkspace[]) => {
                        setWorkspaces(workspace_response);
                        if (workspace) {
                            const group = workspace_response.find(
                                (item: IWorkspace) => item.id === workspace.id
                            );
                            if (!group) {
                                setWorkspace(workspace_response[0]);
                            }
                        }
                        if (workspace_response.length > 0 && !workspace) {
                            setWorkspace(workspace_response[0]);
                        }
                    })
                    .catch((error: AxiosError<ErrorResponse>) => {
                        console.error("DataService.workspace", error);
                    });
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (window.location.pathname !== "/login") {
                            toast.error("Credentials are not valid.");

                            window.location.href = "/login";
                        }
                    } else {
                        toast.error("An unexpected error occurred.");
                    }
                }
            });
        await listSwitchCompanyApi()
            .then(async (response: ISwitchCompany[]) => {
                setSwitchCompany(response);
            })
            .catch((error: AxiosError<ErrorResponse>) => {
            });
    };
    useEffect(() => {
        //console.log("getLocalAccessToken", getLocalAccessToken())
        const token = getLocalAccessToken();
        const refresh = getLocalRefreshToken();
        getRequiredData();

        const intervalCall = setInterval(() => {
            getRequiredData();
        }, 20000);

        if (token !== null && refresh !== null) {
            //console.log("decoded:", jwt_decode(token))
            try {
                setJwtDecoded(jwt_decode(token));
            } catch (e) {
                setIsAuth(false);
                setLocalAccessToken("");
            }
        }

        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, []);

    const selectWorkspace = (workspace_id: string) => {
        //console.log("workspace_id", workspace_id)
        const workspace = workspaces.find(
            (item: IWorkspace) => item.id === workspace_id
        );
        if (workspace) {
            setWorkspace(workspace);
            //console.log("workspace", workspace)
        }
    };

    const [search, setsearch] = useState(false);
    const [megaMenu, setmegaMenu] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);

    const toggleFullscreen = () => {
        let document: any = window.document;
        document.body.classList.add("fullscreen-enable");
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
        // handle fullscreen exit
        const exitHandler = () => {
            if (
                !document.webkitIsFullScreen &&
                !document.mozFullScreen &&
                !document.msFullscreenElement
            )
                document.body.classList.remove("fullscreen-enable");
        };
        document.addEventListener("fullscreenchange", exitHandler);
        document.addEventListener("webkitfullscreenchange", exitHandler);
        document.addEventListener("mozfullscreenchange", exitHandler);
    };

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box d-lg-none d-md-block">
                            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={LOGO_SMALL_PATH} alt="" height="22"/>
                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => tToggle()}
                            className="btn btn-sm px-3 font-size-16 header-item "
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"/>
                        </button>
                    </div>
                    <div className="d-flex">
                        {/*<div className="dropdown d-inline-block d-lg-none ms-2">*/}
                        {/*    <button*/}
                        {/*        onClick={() => setsearch(!search)}*/}
                        {/*        type="button"*/}
                        {/*        className="btn header-item noti-icon "*/}
                        {/*        id="page-header-search-dropdown"*/}
                        {/*    >*/}
                        {/*        <i className="mdi mdi-magnify"/>*/}
                        {/*    </button>*/}
                        {/*    <div*/}
                        {/*        className={*/}
                        {/*            search*/}
                        {/*                ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"*/}
                        {/*                : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"*/}
                        {/*        }*/}
                        {/*        aria-labelledby="page-header-search-dropdown"*/}
                        {/*    >*/}
                        {/*        <form className="p-3">*/}
                        {/*            <div className="form-group m-0">*/}
                        {/*                <div className="input-group">*/}
                        {/*                    <input*/}
                        {/*                        type="text"*/}
                        {/*                        className="form-control"*/}
                        {/*                        placeholder="Search ..."*/}
                        {/*                        aria-label="Recipient's username"*/}
                        {/*                    />*/}
                        {/*                    <div className="input-group-append">*/}
                        {/*                        <button className="btn btn-primary" type="submit">*/}
                        {/*                            <i className="mdi mdi-magnify"/>*/}
                        {/*                        </button>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <LanguageDropdown/>

                        <div className="dropdown d-inline-block ms-1">
                            <Tooltip
                                placement="bottom"
                                isOpen={tbottom}
                                target="TooltipBottom"
                                toggle={() => {
                                    settbottom(!tbottom);
                                }}
                            >
                                {t("Live updates")}: {connectionStatus}
                            </Tooltip>
                            <button
                                type="button"
                                id={"TooltipBottom"}
                                className="btn header-item noti-icon "
                            >
                                <i
                                    className={`mdi ${connectionIcon} color-${
                                        readyState === ReadyState.OPEN ? "success" : "danger"
                                    }`}
                                />
                            </button>
                        </div>

                        {/*<LanguageDropdown/>*/}

                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                onClick={() => {
                                    toggleFullscreen();
                                }}
                                className="btn header-item noti-icon "
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen"/>
                            </button>
                        </div>

                        {/*<NotificationDropDown />*/}
                        <NotificationDropDown/>

                        <ProfileMenu/>
                        <Dropdown
                            className="d-inline-block language-switch"
                            color={"light"}
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                        >
                            <DropdownToggle className="btn header-item" caret>
                                {userInfo?.company.name} <br/>
                                Workspace <br/>
                                {workspace?.name}
                                <i className="mdi mdi-chevron-down"/>
                            </DropdownToggle>
                            <DropdownMenu>
                                {workspaces.map((workspaceItem: IWorkspace, index) => {
                                    return (
                                        <DropdownItem
                                            key={workspaceItem.id}
                                            href="#"
                                            onClick={() => selectWorkspace(workspaceItem.id)}
                                        >
                                            {workspaceItem.name}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default withTranslation()(Header);
