import axios from "axios";
import {API_URL, storageAccessToken, storageRefreshToken} from "../constants";
import {toast} from "react-toastify";
import {ILogin} from "../Types/Common";
import i18n from "../i18n";
import * as Sentry from "@sentry/react";


const api = axios.create()
// default
api.defaults.baseURL = API_URL;

// content type
api.defaults.headers.post["Content-Type"] = "application/json";
// content type
// let authUser: any = (localStorage.getItem("authUser"));

// intercepting to capture errors

function refreshToken(refresh_token:string): Promise<ILogin> {
    //console.log("refreshing token")
    return axios.post(`${API_URL}token/refresh/`,
        {
            refresh: refresh_token,
        },
        {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    ).then(response => {
        console.log("refresh token response", JSON.stringify(response.data))
        return response.data;
    });

}

function forceRefreshToken(refresh_token:string): Promise<ILogin> {
    //console.log("refreshing token")
    return refreshToken(refresh_token)
        .then((newCredentials) => {
            console.log("refresh token success", newCredentials)
            setLocalAccessToken(newCredentials.access);
            setLocalRefreshToken(newCredentials.refresh);
            api.defaults.headers['Authorization'] = 'Bearer ' + newCredentials.access;

        })
        .catch((token_err) => {
            console.error("refresh token error", token_err)
            clearLocalTokens();
            toast.error("refresh token error")


            failedQueue = [];
            //processQueue(Error("refresh token error"), null);
            window.location.reload();
            //window.location.href="/login"
            console.log("api(originalRequest)")
            return token_err
        })

}


export const getLocalAccessToken = () => {
    return localStorage.getItem(storageAccessToken);
};

export const getLocalRefreshToken = () => {
    return localStorage.getItem(storageRefreshToken);
};
export const setLocalAccessToken = (token: string) => {
    localStorage.setItem(storageAccessToken, token);


}
export const setLocalRefreshToken = (token: string) => {
    localStorage.setItem(storageRefreshToken, token);
}

export const clearLocalTokens = () => {
    localStorage.removeItem(storageAccessToken);
    localStorage.removeItem(storageRefreshToken);
}


api.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["Accept-Language"]=i18n.language
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
let isRefreshing = false;
let failedQueue: Array<(errorOrToken: any) => void> = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        console.log("processQueue",prom,error,token)
        if (error) {
            prom(error);
        } else {
            prom(token);
        }
    });

    failedQueue = [];
};

api.interceptors.response.use(
    function (response: any) {
        //console.log("response interceptor. response", response.data)
        return response.data;
    },
    async (error) => {
        //console.log("response interceptor. error", error)

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message: any;
        const originalRequest = error.config;
        Sentry.captureException(error);

        switch (error.response?.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                console.log("401 error", isRefreshing,originalRequest._retry)
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push((token: string | null) => {
                            if (token) {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                resolve(api(originalRequest));
                            } else {
                                reject(error);
                            }
                        });
                    });
                }


                originalRequest._retry = true;


                return new Promise((resolve, reject) => {
                    //console.log("refreshing token", isRefreshing,originalRequest._retry)
                    // Assuming you have a function to refresh the token
                    const refresh_token = getLocalRefreshToken();
                    if (!refresh_token) {
                        clearLocalTokens();
                        console.error("refresh token error", error)
                        return reject(error)
                    }
                    isRefreshing = true;
                    refreshToken(refresh_token)
                        .then((newCredentials) => {
                            console.log("refresh token success", newCredentials)
                            setLocalAccessToken(newCredentials.access);
                            setLocalRefreshToken(newCredentials.refresh);
                            api.defaults.headers['Authorization'] = 'Bearer ' + newCredentials.access;
                            originalRequest.headers['Authorization'] = 'Bearer ' + newCredentials.access;
                            processQueue(null, newCredentials.access);
                            resolve(api(originalRequest));
                        })
                        .catch((token_err) => {
                            console.error("refresh token error", token_err)
                            clearLocalTokens();
                            toast.error("refresh token error")


                            failedQueue = [];
                            //processQueue(Error("refresh token error"), null);
                            window.location.reload();
                            //window.location.href="/login"
                            console.log("api(originalRequest)")
                            return api(originalRequest);

                        })
                        .finally(() => {
                            console.log("finaly")
                            isRefreshing = false;
                        });
                });

                break;
            case 404:
                console.log("404 error")
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }
        console.error("Promise.reject(message)")
        toast.error(`api-common ${message}`,{toastId: "api-common"})
        return Promise.reject(error);
    }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
    /**
     * Fetches data from given url
     */

        //  get = (url, params) => {
        //   return api.get(url, params);
        // };
    get = (url: any, params: any) => {
        let response: any;

        let paramKeys: any = [];

        if (params) {
            Object.keys(params).map(key => {
                paramKeys.push(key + '=' + params[key]);
                return paramKeys;
            });

            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
            response = api.get(`${url}?${queryString}`, params);
        } else {
            response = api.get(`${url}`, params);
        }

        return response;
    };
    /**
     * post given data to url
     */
    create = (url: any, data: any) => {
        return api.post(url, data);
    };
    /**
     * Updates data
     */
    update = (url: any, data: any) => {
        return api.patch(url, data);
    };

    put = (url: any, data: any) => {
        return api.put(url, data);
    };
    /**
     * Delete
     */
    delete = (url: any, config: any) => {
        return api.delete(url, {...config});
    };
}

const getLoggedinUser = () => {
    const user = localStorage.getItem("authUser");
    if (!user) {
        return null;
    } else {
        return JSON.parse(user);
    }
};


export {APIClient, setAuthorization, getLoggedinUser};