import React, { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import { API_URL, storageAccessToken } from "../../constants";
import { IJwt, IMapElement } from "../../Types/Common";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  AccessToken,
  AtomPlayingStreams,
  JwtDecoded,
  RefreshToken,
  UserInfo,
} from "../../Types/atoms";
import { toast } from "react-toastify";
import { getLocalAccessToken } from "../../helpers/api_helper";
import { Container, Row } from "reactstrap";
import gif_loader from "../../assets/images/sardrone/loader.gif";
import { useMapElements } from "../../Providers/MapElementsProvider";
import * as Sentry from "@sentry/react";
import PageErrorBoundary from "../PageErrorBoundary/PageErrorBoundary";

// or init with options

const Debug: React.FC = () => {
  // Use TypeScript annotations for useState
  const [message, setMessage] = useState<string>("");
  const [jwt, setJwt] = useState<IJwt>();
  const [exp, setExp] = useState<Date>();
  const [iat, setIat] = useState<Date>();
  const [logs, setLogs] = useState<any[]>([]);
  const jwtDecoded = useRecoilValue(JwtDecoded);
  const userInfo = useRecoilValue(UserInfo);

  const [playingStreams, setPlayingStreams] =
    useRecoilState(AtomPlayingStreams);
  const { mapElements, setMapElements } = useMapElements();

  // Example of how to modify the map elements
  const addMapElement = (newElement: IMapElement) => {
    setMapElements((prevElements) => [...prevElements, newElement]);
  };

  /*    const accessToken = useRecoilValue(AccessToken);
        const refreshToken = useRecoilValue(RefreshToken);

        useEffect(() => {
            toast.info(`access token changed ${accessToken}`)
        }, [accessToken]);
        useEffect(() => {
            toast.info(`refresh token changed ${refreshToken}`)
        }, [refreshToken]);*/
  useEffect(() => {
    if (!jwt) {
      return;
    }
    setExp(new Date(jwt.exp * 1000));
    setIat(new Date(jwt.iat * 1000));
  }, [jwt]);
  useEffect(() => {
    const token = getLocalAccessToken();

    try {
      if (token) {
        setJwt(jwt_decode(token));
      }
    } catch (e) {
      //clearLocalTokens();
      //console.log('jwt_decode not working', e);
    }
  }, []);
  return (
    <>
      {import.meta.env.NODE_ENV === "development" ? (
        <div className="page-content">
          <Container>
            <Row>
              <code>{JSON.stringify(jwtDecoded)}</code>
              <code>mapElements:{JSON.stringify(mapElements)}</code>

              <code>playingStreams:{JSON.stringify(playingStreams)}</code>
            </Row>
          </Container>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(Debug, { fallback: PageErrorBoundary });
