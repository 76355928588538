// Type definition for GeoJSON

import {Coordinate, IGeoJsonPoint, IGeoJsonPolygon, LineCoordinates, PolygonCoordinates} from "./Types/Common";
import {IDrone} from "./Types/Dron";

export type GeoJSONPoint = {
    type: 'Point';
    coordinates: [number, number];
};

// export type GeoJSONPolygon = {
//     type: 'Feature';
//     geometry: {
//         type: 'Polygon';
//         coordinates: number[][][];
//     };
// };

// Function to convert google.maps.Point to GeoJSON Point
export function convertToPointGeoJSON(point: google.maps.Point): GeoJSONPoint {
    return {
        type: 'Point',
        coordinates: [point.x, point.y]
    };
}

export function convertMarkerToGeoJSON(marker: google.maps.Marker): IGeoJsonPoint {
    return {
        type: "Point",
        coordinates: [marker.getPosition()?.lng() || 0, marker.getPosition()?.lat() || 0]
    };

}
// Function to convert google.maps.Polygon to GeoJSON Polygon
export function convertPolygonToGeoJSON(polygon: google.maps.Polygon): IGeoJsonPolygon {
    const coordinates: PolygonCoordinates = [];

    const paths = polygon.getPaths().getArray();

    paths.forEach(path => {
        const pathCoordinates: LineCoordinates = [];

        path.getArray().forEach(latLng => {
            pathCoordinates.push([latLng.lng(), latLng.lat()]);
        });

        // Close the loop for polygons in GeoJSON format
        if (pathCoordinates.length > 0) {
            pathCoordinates.push(pathCoordinates[0]);
        }

        coordinates.push(pathCoordinates);
    });

    return {
        type: "Polygon",
        coordinates: coordinates
    };
}
export function convertRectangleToGeoJSON(rectangle: google.maps.Rectangle): IGeoJsonPolygon {
    // Get the bounds of the rectangle
    const bounds = rectangle.getBounds();
    if (!bounds) {
        return {
            type: "Polygon",
            coordinates: []
        }
    }
    // Extract the southwest and northeast corners of the bounds
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    // Construct the four corners of the rectangle
    const coordinates: LineCoordinates = [
        [sw.lng(), sw.lat()], // bottom-left
        [ne.lng(), sw.lat()], // bottom-right
        [ne.lng(), ne.lat()], // top-right
        [sw.lng(), ne.lat()], // top-left
        [sw.lng(), sw.lat()]  // closing the loop
    ];

    // Create the GeoJSON polygon
    return {

        type: "Polygon",
        coordinates: [coordinates]

    };

}



export const groupByCompanyName = (data: IDrone[]) => {
    return data.reduce((acc, item) => {
        const companyName = item.active_company_name;

        if (!acc[companyName]) {
            acc[companyName] = [];
        }

        acc[companyName].push(item);
        return acc;
    }, {} as { [key: string]: IDrone[] });
};