import React, {useEffect, useState} from 'react';

import {useLocation, useParams} from "react-router-dom";

import VideoRTC from "../../Components/VideoPlayers/VideoRTC";
import {Col, Row} from "reactstrap";
import Recordings from "../Stream/Recordings";

// URL for the OSSRS server with WHIP support


interface VideoComponentProps {
    stream_id?: string;
}

const Video: React.FC<VideoComponentProps> = ({stream_id}) => {
    const streamLocation = useLocation();
    const params = useParams();
    const [streamId, setStreamId] = useState<string>()


    useEffect(() => {
        //console.log(params)
        //console.log(streamLocation)
        if (streamLocation.state?.id !== undefined) {
            setStreamId(streamLocation.state.id)
        } else if (params.id !== undefined) {
            setStreamId((params.id))
        } else if (stream_id !== null) {
            setStreamId((stream_id))
        } else {
            throw new Error("invalid streamLocation")
        }

    }, [params.id, streamLocation.state?.id, stream_id]);

    return (

        <React.Fragment>
            <div className="page-content">
                <Row >
                    <Col lg={10}>
                        <VideoRTC stream_id={streamId}></VideoRTC>
                    </Col>
                    <Col lg={2}>
                        <Recordings streamId={streamId }></Recordings>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Video;
