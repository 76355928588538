import {EComponentName, EPhotoType} from "./enums";
import {toast} from "react-toastify";
import {EVideoPublishType, LiveStreamStatus} from "./live-stream";
import {API_URL, MQTT_TCP, WEBSOCKET_URL} from "../constants";
import {IDjiVersion} from "./Types";
export const components = new Map()

interface JsResponse{
    code:number,
    message:string,
    data:any
}

export interface ThingParam {
    host: string,
    username: string,
    password: string,
    connectCallback: string
}

export interface LiveshareParam {
    videoPublishType: string, // video-on-demand、video-by-manual、video-demand-aux-manual
    statusCallback: string
}

export interface MapParam {
    userName: string,
    elementPreName: string
}

export interface WsParam {
    host: string,
    token: string,
    connectCallback: string
}

export interface ApiParam {
    host: string,
    token: string
}

export interface MediaParam {
    autoUploadPhoto: boolean, // 是否自动上传图片, 非必需
    autoUploadPhotoType: number, // 自动上传的照片类型，0：原图， 1：缩略图， 非必需
    autoUploadVideo: boolean // 是否自动上传视频， 非必需
}

function returnBool (response: string): boolean {
    const res: JsResponse = JSON.parse(response)
    const isError = errorHint(res)
    if (JSON.stringify(res.data) !== '{}') {
        return isError && res.data
    }
    return isError
}

function returnString (response: string): string {
    const res: JsResponse = JSON.parse(response)
    return errorHint(res) ? res.data : ''
}

function returnNumber (response: string): number {
    const res: JsResponse = JSON.parse(response)
    return errorHint(res) ? res.data : -1
}

function errorHint (response: JsResponse): boolean {
    if (response.code !== 0) {
        //toast.error(response.message)
        console.error(response.message)
        return false
    }
    return true
}

export default {
    init (): Map<EComponentName, any> {
        const thingParam: ThingParam = {
            //host: 'https://sarmqtt.ngrok.dev/mqtt',
            //host: 'mqtt://nomad.lan:1883',
            //host: '',
            //host: 'ws://nomad.lan:8083/mqtt',
            host:'',
            username: '',
            password: '',
            connectCallback: ''
        }
        components.set(EComponentName.Thing, thingParam)
        const liveshareParam: LiveshareParam = {
            videoPublishType: EVideoPublishType.VideoDemandAuxManual,
            statusCallback: 'liveStatusCallback'
        }
        components.set(EComponentName.Liveshare, liveshareParam)
        const mapParam: MapParam = {
            userName: '',
            elementPreName: 'PILOT'
        }
        components.set(EComponentName.Map, mapParam)
        const wsParam: WsParam = {
            host: WEBSOCKET_URL,
            token: 'token',
            connectCallback: 'wsConnectCallback'
        }
        components.set(EComponentName.Ws, wsParam)
        const apiParam: ApiParam = {
            host: API_URL,
            token: 'tokenapi',
        }
        components.set(EComponentName.Api, apiParam)
        components.set(EComponentName.Tsa, {})
        const mediaParam: MediaParam = {
            autoUploadPhoto: false,
            autoUploadPhotoType: EPhotoType.Preview,
            autoUploadVideo: true
        }
        components.set(EComponentName.Media, mediaParam)
        components.set(EComponentName.Mission, {})

        return components
    },

    getComponentParam (key:EComponentName): any {
        return components.get(key)
    },
    setComponentParam (key:EComponentName, value:any) {
        components.set(key, value)
    },
    loadComponent (name:string, param:any):string {
        return returnString(window.djiBridge.platformLoadComponent(name, JSON.stringify(param)))
    },
    unloadComponent (name:string) :string {
        return returnString(window.djiBridge.platformUnloadComponent(name))
    },
    isComponentLoaded (module:string): boolean {
        return returnBool(window.djiBridge.platformIsComponentLoaded(module))
    },
    setWorkspaceId (uuid:string):string {
        return returnString(window.djiBridge.platformSetWorkspaceId(uuid))
    },
    setPlatformMessage (platformName:string, title:string, desc:string): boolean {
        return returnBool(window.djiBridge.platformSetInformation(platformName, title, desc))
    },
    getRemoteControllerSN () :string {
        return returnString(window.djiBridge.platformGetRemoteControllerSN())
    },
    getAircraftSN ():string {
        return returnString(window.djiBridge.platformGetAircraftSN())
    },
    stopwebview ():string {
        return returnString(window.djiBridge.platformStopSelf())
    },
    setLogEncryptKey (key:string):string {
        return window.djiBridge.platformSetLogEncryptKey(key)
    },
    clearLogEncryptKey ():string {
        return window.djiBridge.platformClearLogEncryptKey()
    },
    getLogPath ():string {
        return returnString(window.djiBridge.platformGetLogPath())
    },
    platformVerifyLicense (appId:string, appKey:string, appLicense:string): boolean {
        return returnBool(window.djiBridge.platformVerifyLicense(appId, appKey, appLicense))
    },
    isPlatformVerifySuccess (): boolean {
        return returnBool(window.djiBridge.platformIsVerified())
    },
    isAppInstalled (pkgName: string): boolean {
        return returnBool(window.djiBridge.platformIsAppInstalled(pkgName))
    },
    getVersion (): IDjiVersion {
        return JSON.parse(window.djiBridge.platformGetVersion()).data
    },

    // thing
    thingGetConnectState (): boolean {
        //console.log("bridge",window.djiBridge.thingGetConnectState())

        return returnBool(window.djiBridge.thingGetConnectState())
    },

    thingGetConfigs (): ThingParam {
        const thingParam = JSON.parse(window.djiBridge.thingGetConfigs())
        return thingParam.code === 0 ? JSON.parse(thingParam.data) : {}
    },

    // api
    getToken () : string {
        return returnString(window.djiBridge.apiGetToken())
    },
    setToken (token:string):string {
        return returnString(window.djiBridge.apiSetToken(token))
    },
    getHost (): string {
        return returnString(window.djiBridge.apiGetHost())
    },

    // liveshare
    /**
     *
     * @param type
     * video-on-demand: 服务器点播，依赖于thing模块，具体的点播命令参见设备物模型的直播服务
     * video-by-manual：手动点播，配置好直播类型参数之后，在图传页面可修改直播参数，停止直播
     * video-demand-aux-manual: 混合模式，支持服务器点播，以及图传页面修改直播参数，停止直播
     */
    setVideoPublishType (type:string): boolean {
        return returnBool(window.djiBridge.liveshareSetVideoPublishType(type))
    },

    /**
     *
     * @returns
     * type: liveshare type， 0：unknown, 1:agora, 2:rtmp, 3:rtsp, 4:gb28181
     */
    getLiveshareConfig (): string {
        return returnString(window.djiBridge.liveshareGetConfig())
    },

    setLiveshareConfig (type:number, params:string):string {
        console.log("setLiveshareConfig",type,params)
        return window.djiBridge.liveshareSetConfig(type, params)
    },

    setLiveshareStatusCallback (callbackFunc:string) :string {
        return window.djiBridge.liveshareSetStatusCallback(callbackFunc)
    },
    getLiveshareStatus (): LiveStreamStatus {
        console.log(window.djiBridge.liveshareGetStatus())
        return JSON.parse(window.djiBridge.liveshareGetStatus())
    },
    startLiveshare (): boolean {
        return returnBool(window.djiBridge.liveshareStartLive())
    },
    stopLiveshare (): boolean {
        return returnBool(window.djiBridge.liveshareStopLive())
    },
    // WebSocket
    wsGetConnectState (): boolean {
        return returnBool(window.djiBridge.wsGetConnectState())
    },
    wsConnect (host: string, token: string, callback: string): string {
        return window.djiBridge.wsConnect(host, token, callback)
    },
    wsDisconnect (): string {
        return window.djiBridge.wsConnect()
    },
    wsSend (message: string): string {
        return window.djiBridge.wsSend(message)
    },
    // media
    setAutoUploadPhoto (auto:boolean):string {
        return window.djiBridge.mediaSetAutoUploadPhoto(auto)
    },
    getAutoUploadPhoto (): boolean {
        return returnBool(window.djiBridge.mediaGetAutoUploadPhoto())
    },
    setUploadPhotoType (type:number):string {
        return window.djiBridge.mediaSetUploadPhotoType(type)
    },
    getUploadPhotoType (): number {
        return returnNumber(window.djiBridge.mediaGetUploadPhotoType())
    },
    setAutoUploadVideo (auto:boolean):string {
        return window.djiBridge.mediaSetAutoUploadVideo(auto)
    },
    getAutoUploadVideo (): boolean {
        return returnBool(window.djiBridge.mediaGetAutoUploadVideo())
    },
    setDownloadOwner (rcIndex:number):string {
        return window.djiBridge.mediaSetDownloadOwner(rcIndex)
    },
    getDownloadOwner (): number {
        return returnNumber(window.djiBridge.mediaGetDownloadOwner())
    },
    onBackClickReg () {
        console.log(window.djiBridge)
        if (!window.djiBridge) return;
        window.djiBridge.onBackClick = () => {
            console.log("onBackClickReg",window.location.pathname)

            if (window.location.pathname === '/pilot-login') {
                return false
            } else {
                window.history.go(-1)
                return true
            }
        }
    },
    onStopPlatform () {
        window.djiBridge.onStopPlatform = () => {
            localStorage.clear()
        }
    }
}
