import React, { useEffect, useState } from "react";

// Redux
import { Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// import images
import profile from "../../assets/images/sardrone/SARDRONE-logo-02.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

//import thunk

import withRouter from "../../Components/Common/withRouter";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import { ErrorResponse, ILogin } from "../../Types/Common";
import { AxiosError } from "axios/index";
import {LOGO_BIG_PATH, LOGO_BIG_PATH_PNG, navBarTitle} from "../../constants";
import { LoginApi, LoginKeyApi } from "../../helpers/sarlink_backed";
import {
  clearLocalTokens,
  setLocalAccessToken,
  setLocalRefreshToken,
} from "../../helpers/api_helper";
import classnames from "classnames";
import LoginPilot from "./LoginPilot";
import LoginNormal from "./LoginNormal";

const Login = (props: any) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [minimal, setMinimal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(props);
    if (window.djiBridge !== undefined) {
      setcustomActiveTab("2");
      setMinimal(true);
    }
    const query = new URLSearchParams(location.search);
    const sesame = query.get("sesame"); // Assuming you have a parameter named 'id'
    if (sesame) {
      setcustomActiveTab("2");
    }
  }, []);
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  //meta title
  document.title = `Login | ${navBarTitle}`;

  return (
    <React.Fragment>
      <div className="account-pages my-1 pt-sm-1">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  {!minimal && (
                    <Row>
                      <Col className="col-9">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to {navBarTitle}</p>
                        </div>
                      </Col>
                      <Col className="col-3 align-self-center">
                        <img
                          src={LOGO_BIG_PATH_PNG}
                          alt=""
                          className="img-fluid"
                          style={{ maxHeight: "100px" }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Username & Password
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Key login</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <LoginNormal {...props}></LoginNormal>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <LoginPilot {...props}></LoginPilot>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&apos;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {navBarTitle} Platform.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
