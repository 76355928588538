
import usflag from "../assets/images/flags/us.jpg";
import deflag from "../assets/images/flags/germany.jpg";
import siflag from "../assets/images/flags/slovenia.jpg";
const languages : Object = {


    en:{
        label:"English",
        flag :usflag
    },
    de:{
        label:"Germany",
        flag :deflag
    },
    sl:{
        label:"Slovenia",
        flag :siflag
    }
}

export default languages