import React from "react";
import { Container, Row, Col } from "reactstrap";
import {navBarCompany, navBarTitle} from "../../constants";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t, i18n} = useTranslation();

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © {navBarTitle} {navBarCompany}. {t("All Rights Reserved")}.
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                {import.meta.env.VITE_VERSION} @{" "}
                {import.meta.env.VITE_VERSION_DATE}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
