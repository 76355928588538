import React, {useState} from "react";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {Slider} from "antd";

const Flights: React.FC = (props, context) => {
    const [float_val, setfloat_val] = useState(55.5)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <CardTitle>React Rangeslider</CardTitle>
                            <Row>
                                <Col xl={6}>
                                    <div className="p-3">
                                        <h5 className="font-size-14 mb-3 mt-0">
                                            Prettify Numbers
                                        </h5>
                                        <span className="float-start mt-4">1</span>{" "}
                                        <span className="float-end mt-4">100</span>
                                        <Slider
                                            value={float_val}
                                            step={0.5}
                                            onChange={(value: any) => {
                                                setfloat_val(value)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
};
export default Flights;