import React, {memo, useEffect, useLayoutEffect, useState} from "react";
import {Route, Link, Routes, useParams} from "react-router-dom";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import {Alert, Badge, Card, CardBody, Col, Container, Row} from "reactstrap";
import {IDrone} from "../../Types/Dron";
import {
    getDroneInfoApi,
    listDroneStreamsApi,
    updateDeviceApi,
} from "../../helpers/sarlink_backed";
import classnames from "classnames";
import {EWsMsgType, IWsDroneEvent, WsMessage} from "../../Types/Ws";
import {
    IDashboardData,
    ILastBattery,
    ILastLocation,
    ILastPayload,
} from "../../Types/Dashboard";
import {IGeoJsonLineString, IMapElement, IStream} from "../../Types/Common";
import StreamCard from "../Stream/StreamCard";
import MapGoogleProvider from "../Map/MapProvider/Google";
import {useJsApiLoader} from "@react-google-maps/api";
import {defaultLibraries, GoogleApiKey} from "../../constants";
import {ILocation} from "../Map/MapProvider/Types";
import {useRecoilState} from "recoil";
import {AtomAditionalMarkers} from "../../Types/atoms";
import {useSharedWebSocket} from "../../Providers/WebsocketProvider";
import IconTarget from "../../assets/target-svgrepo-com.svg";
import IconRC from "../../assets/remote-control-drone-svgrepo-com.svg";
import Spinners from "../../Components/Common/Spinner";
import isEqual from "react-fast-compare";
import {useMapElements} from "../../Providers/MapElementsProvider";
import {convertDegreesToDirection} from "../../helpers/helpers";
import {toast} from "react-toastify";
import {FaCheck, FaTimes} from "react-icons/fa";
import EditDroneName from "../../partials/EditDroneName";
import {useTranslation} from "react-i18next";

interface DronComponentProps {
    droneData?: IDrone;
}

const Dron: React.FC<DronComponentProps> = (props, context) => {
    const {t, i18n} = useTranslation();
    const params = useParams();
    const [droneData, setDroneData] = React.useState<IDrone>();
    const [lastBatteryData, setLastBatteryData] = React.useState<ILastBattery[]>(
        []
    );
    const [lastLocationData, setLastLocationData] = React.useState<
        ILastLocation | undefined
    >();
    const [lastLocationRcData, setLastLocationRcData] = React.useState<
        ILastLocation | undefined
    >();
    const [lastPayloadData, setLastPayloadData] = React.useState<ILastPayload[]>(
        []
    );
    const [streamsData, setStreamsData] = React.useState<IStream[]>([]);
    const [mapElementsDrone, setMapElementsDrone] = useState<IMapElement[]>([]);
    const [centerLocation, setCenterLocation] = useState<ILocation>({
        latitude: 0,
        longitude: 0,
    });
    const [markerBounds, setMarkerBounds] =
        useState<google.maps.LatLngBounds | null>(null);
    const [additionalMarkers, setAdditionalMarkers] =
        useRecoilState(AtomAditionalMarkers);
    const {sendMessage, lastMessage, readyState} = useSharedWebSocket();
    const [hudHtml, setHudHtml] = useState<JSX.Element>(<></>);
    const [enableBoundDrone, setEnableBoundDrone] = useState(true);
    const [enableBoundUser, setEnableBoundUser] = useState(false);
    const [enableShowFlightPath, setEnableShowFlightPath] = useState(true);
    const [enableShowMapElements, setEnableShowMapElements] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const {mapElements, setMapElements} = useMapElements();

    const {id} = params;
    const googleMapsLoader = useJsApiLoader({
        id: `map-${i18n.language}`,
        language: i18n.language,
        region: i18n.language,
        googleMapsApiKey: GoogleApiKey,
        libraries: defaultLibraries,
        //libraries:["drawing"]
    });
    const removeItemMapElement = (itemId: string) => {
        if (!itemId) return;
        const newItems = mapElementsDrone.filter((item) => {
            //console.log("removeItemMapElement",item.refId, itemId.toString(),item.refId !== itemId.toString())

            return item.refId !== itemId.toString();
        });
        //console.log("removeItemMapElement",itemId,newItems)
        if (newItems.length === mapElementsDrone.length) return;
        setMapElementsDrone(newItems);
    };

    const addOrUpdateItemMapElements = (item: IMapElement) => {
        setMapElementsDrone((prevItems) => {
            // Check if the item already exists
            const existingItem = prevItems.find((i) => i.id === item.id);

            if (existingItem) {
                //console.log("update")
                // Update existing item
                return prevItems.map((i) => (i.id === item.id ? item : i));
            } else {
                // Add new item
                //console.log("add", item)
                return [...prevItems, item];
            }
        });
    };
    useEffect(() => {
        console.log("mapElements changed", mapElements);
    }, [mapElements]);
    useLayoutEffect(() => {
        if (!googleMapsLoader.isLoaded) return;
        if (droneData === undefined) return;
        const refid = `lastflightline-${droneData.sn}`;

        if (droneData.last_flight_path === null) {
            removeItemMapElement(refid);
            return;
        }

        if (enableShowFlightPath) {
            addOrUpdateItemMapElements({
                id: `lastflightline-${droneData.sn}`,
                refId: refid,

                group: "",
                name: "flight path",
                center: {
                    latitude: droneData.last_flight_path.coordinates[0][1],
                    longitude: droneData.last_flight_path.coordinates[0][0],
                },
                line: {
                    type: "LineString",
                    coordinates: droneData.last_flight_path.coordinates,
                },
                bounds: [
                    droneData.last_flight_path.coordinates[0][1],
                    droneData.last_flight_path.coordinates[0][0],
                    droneData.last_flight_path.coordinates[0][1],
                    droneData.last_flight_path.coordinates[0][0],
                ],
                color: "orange",
                color_display: "orange",
            });
        } else {
            removeItemMapElement(refid);
        }
    }, [
        droneData?.last_flight_path,
        googleMapsLoader.isLoaded,
        enableShowFlightPath,
    ]);
    const growClassname = "flex-md-row flex-md-grow-1 ";

    useLayoutEffect(() => {
        if (!googleMapsLoader.isLoaded) return;
        if (lastLocationData === undefined) return;
        if (droneData === undefined) return;
        var name = droneData.full_name;
        var dron_icon_url = `/assets/icon-sardrone/icon-sardrone-${Math.round(
            lastLocationData.attitude_head
        )}.svg`;
        if (!lastLocationData.in_the_sky) {
            //console.log("ground", lastLocationData.in_the_sky)
            dron_icon_url = `/assets/icon-sardrone/icon-sardrone-ground.svg`;
        }

        const mapElement: IMapElement = {
            id: droneData.id.toString(),
            group: "",
            name: name,
            center: {
                latitude: lastLocationData.location.coordinates[1],
                longitude: lastLocationData.location.coordinates[0],
            },
            point: {
                type: "Point",
                coordinates: lastLocationData.location.coordinates,
            },
            bounds: [
                lastLocationData.location.coordinates[1],
                lastLocationData.location.coordinates[0],
                lastLocationData.location.coordinates[1],
                lastLocationData.location.coordinates[0],
            ],
            color: "red",
            color_display: "red",
            icon: {
                anchor: new google.maps.Point(15, 15),
                scaledSize: new google.maps.Size(30, 30),
                url: dron_icon_url,
                labelOrigin: new google.maps.Point(15, 40),
            },
        };
        addOrUpdateItemMapElements(mapElement);
        const html = (
            <Row
                style={{
                    background: "rgba(178,182,182,0.7)",
                    color: "black",
                    fontSize: "0.8rem",
                }}
            >
                <Col md={12}>
                    {t("Speed")}:{" "}
                    <b>
                        {" "}
                        {lastLocationData?.horizontal_speed &&
                            `${lastLocationData?.horizontal_speed.toFixed(1)} km/h`}
                    </b>
                </Col>
                <Col md={12}>
                    {t("Location")}: {" "}
                    <b>
                        {lastLocationData?.location.coordinates[1].toFixed(6)},{" "}
                        {lastLocationData?.location.coordinates[0].toFixed(6)}
                    </b>
                </Col>
                {lastLocationData?.wind_direction && (
                    <Col md={12}>
                        {t("Wind")}:{" "}
                        <b>
                            {lastLocationData?.wind_direction_display}{" "}
                            {lastLocationData?.wind_speed?.toFixed(0)} km/h{" "}
                        </b>
                    </Col>
                )}
                {lastPayloadData.map((payload) => {
                    if (payload.measure_target_location.coordinates[0] == 0) return;
                    if (payload.measure_target_error_state != 0) {
                        return (
                            <Col key={`hud-${payload.payload.sn}`} md={12}>
                                {t("RNG Error")}:<b> {payload.measure_target_error_state_display}</b>
                            </Col>
                        );
                    }
                    return (
                        <div key={`hud-${payload.payload.sn}`}>
                            <Col md={12}>
                                {t("RNG Distance")}:{" "}
                                <b>{Math.round(payload.measure_target_distance)}m </b>
                            </Col>
                            <Col md={12}>
                                {t("RNG Location")}:{" "}
                                <b>
                                    {payload.measure_target_location.coordinates[1].toFixed(6)},
                                    {payload.measure_target_location.coordinates[0].toFixed(6)}
                                </b>
                            </Col>
                        </div>
                    );
                })}
                <Row className={"d-flex d-none d-md-inline-flex "}>
                    <Col sm={12}>
                        <div className="form-check form-switch mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwBoundDrone"
                                checked={enableBoundDrone}
                                onChange={(e) => {
                                    setEnableBoundDrone(!enableBoundDrone);
                                }}
                            />
                            <label className="form-check-label" htmlFor="customSwBoundDrone">
                                {t("Bound zoom to drone")}                            </label>
                        </div>
                    </Col>

                    <Col sm={12}>
                        <div className="form-check form-switch mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwUser"
                                checked={enableBoundUser}
                                onChange={(e) => {
                                    setEnableBoundUser(!enableBoundUser);
                                }}
                            />
                            <label className="form-check-label" htmlFor="customSwUser">
                                {t("Bound zoom user locations")}
                            </label>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="form-check form-switch mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwFlightPath"
                                checked={enableShowFlightPath}
                                onChange={(e) => {
                                    setEnableShowFlightPath(!enableShowFlightPath);
                                }}
                            />
                            <label className="form-check-label" htmlFor="customSwFlightPath">
                                {t("Show flight path")}
                            </label>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="form-check form-switch mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwMapElements"
                                checked={enableShowMapElements}
                                onChange={(e) => {
                                    setEnableShowMapElements(!enableShowMapElements);
                                }}
                            />
                            <label className="form-check-label" htmlFor="customSwMapElements">
                                {t("Show markers")} {" "}
                                <Badge color={"secondary"}>{mapElements.length}</Badge>
                            </label>
                        </div>
                    </Col>
                </Row>
            </Row>
        );
        setHudHtml(html);
    }, [
        lastLocationData,
        lastPayloadData,
        googleMapsLoader.isLoaded,
        enableShowMapElements,
        enableShowFlightPath,
        enableBoundUser,
        enableBoundDrone,
    ]);
    useLayoutEffect(() => {
        if (!googleMapsLoader.isLoaded) return;
        if (lastLocationRcData === undefined) return;
        if (droneData === undefined) return;
        addOrUpdateItemMapElements({
            id: droneData.parent.id.toString(),
            group: "",
            name: droneData.parent.full_name,
            center: {
                latitude: lastLocationRcData.location.coordinates[1],
                longitude: lastLocationRcData.location.coordinates[0],
            },
            point: {
                type: "Point",
                coordinates: lastLocationRcData.location.coordinates,
            },
            bounds: [
                lastLocationRcData.location.coordinates[1],
                lastLocationRcData.location.coordinates[0],
                lastLocationRcData.location.coordinates[1],
                lastLocationRcData.location.coordinates[0],
            ],
            color: "red",
            color_display: "red",
            icon: {
                scaledSize: new google.maps.Size(30, 30),
                url: IconRC,
                labelOrigin: new google.maps.Point(15, 40),
            },
        });
    }, [lastLocationRcData, googleMapsLoader.isLoaded]);
    useLayoutEffect(() => {
        if (!googleMapsLoader.isLoaded) return;
        if (lastPayloadData === undefined) return;
        if (droneData === undefined) return;
        lastPayloadData.forEach((payload) => {
            if (payload.measure_target_location.coordinates[0] == 0) {
                removeItemMapElement(payload.payload.sn);
                return;
            }

            if (payload.measure_target_error_state != 0) {
                removeItemMapElement(payload.payload.sn);
                return;
            }
            addOrUpdateItemMapElements({
                id: `payload-${payload.payload.sn}`,
                refId: payload.payload.sn,
                group: "",
                name: `${t("RNG Distance")}: ${Math.round(payload.measure_target_distance)}m`,

                center: {
                    latitude: payload.measure_target_location.coordinates[1],
                    longitude: payload.measure_target_location.coordinates[0],
                },
                point: {
                    type: "Point",
                    coordinates: payload.measure_target_location.coordinates,
                },
                bounds: [
                    payload.measure_target_location.coordinates[1],
                    payload.measure_target_location.coordinates[0],
                    payload.measure_target_location.coordinates[1],
                    payload.measure_target_location.coordinates[0],
                ],
                color: "red",
                color_display: "red",
                icon: {
                    anchor: new google.maps.Point(15, 15),

                    scaledSize: new google.maps.Size(30, 30),
                    url: IconTarget,
                    labelOrigin: new google.maps.Point(15, 40),
                },
            });
            addOrUpdateItemMapElements({
                id: `payloadline-${payload.payload.sn}`,
                refId: payload.payload.sn,

                group: "",
                name: droneData.full_name,
                center: {
                    latitude: payload.measure_target_location.coordinates[1],
                    longitude: payload.measure_target_location.coordinates[0],
                },
                line: {
                    type: "LineString",
                    coordinates: [
                        [
                            payload.dron_location.coordinates[0],
                            payload.dron_location.coordinates[1],
                        ],
                        [
                            payload.measure_target_location.coordinates[0],
                            payload.measure_target_location.coordinates[1],
                        ],
                    ],
                },
                bounds: [
                    payload.measure_target_location.coordinates[1],
                    payload.measure_target_location.coordinates[0],
                    payload.measure_target_location.coordinates[1],
                    payload.measure_target_location.coordinates[0],
                ],
                color: "blue",
                color_display: "blue",
                icon: {
                    scaledSize: new google.maps.Size(30, 30),
                    url: IconTarget,
                    labelOrigin: new google.maps.Point(15, 40),
                },
            });
        });
    }, [lastPayloadData, googleMapsLoader.isLoaded]);

    useEffect(() => {
        if (!mapElementsDrone) return;
        //console.log(googleMapsLoader.isLoaded)
        if (!googleMapsLoader.isLoaded) return;
        const bounds = new google.maps.LatLngBounds();
        if (enableBoundDrone) {
            mapElementsDrone.forEach((mapElement: IMapElement) => {
                if (
                    mapElement.center.latitude === 0 &&
                    mapElement.center.longitude === 0
                )
                    return;
                //console.log("enable mapElementsDrone",mapElement.name, mapElement.center.latitude, mapElement.center.longitude)

                bounds.extend({
                    lat: mapElement.center.latitude,
                    lng: mapElement.center.longitude,
                });
            });

            setMarkerBounds(bounds);
        }
        if (enableBoundUser) {
            additionalMarkers.forEach((mapElement: IMapElement) => {
                if (
                    mapElement.center.latitude === 0 &&
                    mapElement.center.longitude === 0
                )
                    return;
                //console.log("enable additionalMarkers",mapElement.name, mapElement.center.latitude, mapElement.center.longitude)
                bounds.extend({
                    lat: mapElement.center.latitude,
                    lng: mapElement.center.longitude,
                });
            });

            setMarkerBounds(bounds);
        }
    }, [
        mapElementsDrone,
        additionalMarkers,
        enableBoundDrone,
        enableBoundUser,
        googleMapsLoader.isLoaded,
    ]);
    useEffect(() => {
        if (!enableBoundUser) return;

        if (!googleMapsLoader.isLoaded) return;
        if (!additionalMarkers) return;
    }, [additionalMarkers, googleMapsLoader.isLoaded]);
    useLayoutEffect(() => {
        if (lastMessage === null) return;
        const data: WsMessage = JSON.parse(lastMessage?.data);
        //console.log("data.msg_type", data.msg_type, data.data, data.data_list)

        //console.log("stream_changed",data.msg_type=="stream_changed",data.msg_type,EWsMsgType.STREAM_CHANGED)
        if (data.msg_type === EWsMsgType.STREAM_CHANGED) {
            console.log("updating streams");
            getDroneStreams();
            return;
        }
        if (data.device_id !== droneData?.id) {
            //console.log("data.device_id", data.device_id, droneData?.id)
            return;
        }
        if (data.msg_type === EWsMsgType.DRONE) {
            //console.log("setDroneData")
            setDroneData(data.data as IDrone);
            return;
        }
        if (data.msg_type === EWsMsgType.BATTERY) {
            setLastBatteryData(data.data_list as ILastBattery[]);
            return;
        }
        if (data.msg_type === EWsMsgType.LAST_LOCATION) {
            setLastLocationData(data.data as ILastLocation);
            return;
        }
        if (data.msg_type === EWsMsgType.LAST_LOCATION_RC) {
            setLastLocationRcData(data.data as ILastLocation);
            return;
        }
        if (data.msg_type === EWsMsgType.PAYLOAD) {
            setLastPayloadData(data.data_list as ILastPayload[]);
            return;
        }
    }, [lastMessage]);

    const getDroneStreams = async () => {
        if (id === undefined) return;

        await listDroneStreamsApi(id)
            .then((data: IStream[]) => {
                setStreamsData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDroneInfoData = async () => {
        await getDroneInfoApi(id)
            .then((response: IDrone) => {
                if (response.last_location) {
                    setLastLocationData(response.last_location);
                }
                if (response.last_location_rc) {
                    setLastLocationRcData(response.last_location_rc);
                }
                setStreamsData(response.streams);
                setLastBatteryData(response.last_battery);
                setLastPayloadData(response.last_payload);
                setDroneData(response as IDrone);

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getDroneInfoData();
        const intervalCall = setInterval(() => {
            getDroneInfoData();
        }, 30000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [id]);

    if (droneData === undefined)
        return <>{isLoading && <Spinners setLoading={setLoading}/>}</>;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/*<Breadcrumb title="Dron" breadcrumbItem={droneData?.full_name}/>*/}
                    <EditDroneName {...droneData} />
                    {isLoading && <Spinners setLoading={setLoading}/>}

                    <Col xl={12} key={`dashboard-${droneData.id}`}>
                        <h2>
                            <div className="d-flex gap-2">
                                {droneData.parent.is_online ? (
                                    <div className="d-inline">
                                        <Badge pill color="success">
                                            RC Online
                                        </Badge>
                                    </div>
                                ) : (
                                    <div className="d-inline">
                                        {" "}
                                        <Badge pill color="danger">
                                            RC Offline
                                        </Badge>
                                    </div>
                                )}
                                {droneData.is_online ? (
                                    <div className="d-inline">
                                        <Badge pill color="success">
                                            {t("Dron")} {t("Online")}
                                        </Badge>
                                    </div>
                                ) : (
                                    <div className="d-inline">
                                        <Badge pill color="danger">
                                            {t("Dron")} {t("Offline")}
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        </h2>
                        <Row className={classnames({blur: !droneData.is_online})}>
                            <Col md={4}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex flex-column flex-xl-row ">
                                            <Row className={growClassname}>
                                                {lastBatteryData
                                                    .sort((a, b) => (a.index > b.index) ? 1 : -1)
                                                    .map((battery_info: ILastBattery) => {
                                                        return (
                                                            <Col xs={4} key={`bat-${battery_info.sn}-${battery_info.index}`}>
                                                                <div>
                                                                    <p className="text-muted text-truncate mb-2">
                                                                        {t("Battery")}: {battery_info.index + 1}{" "}
                                                                    </p>
                                                                    <h5 className="mb-0">
                                                                        {battery_info.capacity_percent}%<br/>
                                                                        <small>{battery_info.voltage / 1000}V</small>
                                                                    </h5>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>

                                            <div
                                                className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"bx bx-battery font-size-24"}></i>
                        </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={8}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex flex-column flex-xl-row">
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("In the air")}{" "}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.in_the_sky.toString()}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Speed")}{" "}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.horizontal_speed &&
                                                            `${lastLocationData?.horizontal_speed.toFixed(
                                                                1
                                                            )} km/h`}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Elevation")}{" "}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.elevation &&
                                                            `${lastLocationData?.elevation?.toFixed(1)} m`}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">{t("Heading")} </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.attitude_head &&
                                                            `${lastLocationData?.attitude_head?.toFixed(
                                                                1
                                                            )}° ${convertDegreesToDirection(
                                                                lastLocationData?.attitude_head
                                                            )}`}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Status")}{" "}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.mode_code_display}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">{t("Gear")} </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.gear_display}
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">{t("Wind")} </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.wind_speed?.toFixed(0)} km/h
                                                        <br/>
                                                        <small>
                                                            {lastLocationData?.wind_direction_display}
                                                        </small>
                                                    </h5>
                                                </div>
                                            </Row>
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        {t("Location")}{" "}
                                                    </p>
                                                    <h5 className="mb-0">
                                                        {lastLocationData?.location.coordinates[1].toFixed(
                                                            6
                                                        )}
                                                        ,{" "}
                                                        {lastLocationData?.location.coordinates[0].toFixed(
                                                            6
                                                        )}
                                                    </h5>
                                                </div>
                                            </Row>

                                            <div
                                                className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"bx bx-map font-size-24"}></i>
                        </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {droneData.last_events.filter(
                            (event) => event.sn === droneData.sn
                        ).length > 0 && (

                            <Col md={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <div className="d-flex flex-column flex-xl-row ">
                                            <Row className={growClassname}>
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        <b>Drone events/errors:</b>
                                                    </p>
                                                    <h6 className="mb-0">
                                                        {droneData.last_events.map(
                                                            (event: IWsDroneEvent, index) => {
                                                                if (event.sn === droneData.sn) {
                                                                    return (
                                                                        <Alert
                                                                            className={"me-2"}
                                                                            key={`event-${index}-${event.code_hex}`}
                                                                            color={"warning"}
                                                                        >
                                                                            {event.code} - {event.parsed}
                                                                        </Alert>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </h6>
                                                </div>
                                            </Row>

                                            <div
                                                className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"mdi mdi-alert font-size-24"}></i>
                        </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        <Row key={droneData.id}>
                            <Col xl={8} className={"vh-80"}>
                                <Row className={"d-flex d-lg-none"}>
                                    <Col>
                                        <div className="form-check form-switch mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwBoundDrone"
                                                checked={enableBoundDrone}
                                                onChange={(e) => {
                                                    setEnableBoundDrone(!enableBoundDrone);
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="customSwBoundDrone"
                                            >
                                                {t("Bound zoom to drone")}
                                            </label>
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="form-check form-switch mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwUser"
                                                checked={enableBoundUser}
                                                onChange={(e) => {
                                                    setEnableBoundUser(!enableBoundUser);
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="customSwUser"
                                            >
                                                {t("Bound zoom user locations")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-check form-switch mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwFlightPath"
                                                checked={enableShowFlightPath}
                                                onChange={(e) => {
                                                    setEnableShowFlightPath(!enableShowFlightPath);
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="customSwFlightPath"
                                            >
                                                {t("Show flight path")}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-check form-switch mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwMapElements"
                                                checked={enableShowMapElements}
                                                onChange={(e) => {
                                                    setEnableShowMapElements(!enableShowMapElements);
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="customSwMapElements"
                                            >
                                                {t("Show markers")}{" "}
                                                <Badge color={"secondary"}>{mapElements.length}</Badge>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <MapGoogleProvider
                                    mapElements={mapElementsDrone}
                                    center_location={centerLocation}
                                    zoom={15}
                                    markerBounds={markerBounds}
                                    isLoaded={googleMapsLoader.isLoaded}
                                    additionalMarkers={
                                        enableShowMapElements
                                            ? [...additionalMarkers, ...mapElements]
                                            : additionalMarkers
                                    }
                                    hudHtml={hudHtml}
                                ></MapGoogleProvider>
                            </Col>
                            <Col xl={4}>
                                {streamsData.map((stream: IStream) => {
                                    return (
                                        <Row key={stream.id} className={""}>
                                            <StreamCard {...stream} />
                                        </Row>
                                    );
                                })}
                            </Col>


                        </Row>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default memo(Dron, isEqual);
