import React, {useEffect, useState, useTransition} from "react";
import {authProtectedRoutes, publicRoutes} from "./Routes/allRoutes";
import {
    createRoutesFromChildren,
    matchRoutes,
    Route,
    Routes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import VConsole from "vconsole";

type ThemeName = 'light' | 'dark';


import NonAuthLayout from "./Layouts/NonLayout";

//constants
import {
    API_URL, BRAND_NAME, BRAND_NAMES,
    DisableVConsole,
    navBarTitle,
    SENTRY_DNS,
    WEBSOCKET_URL,
} from "./constants";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {RecoilRoot, useRecoilState} from "recoil";
import {ErrorBoundary} from "react-error-boundary";
import {LocationProvider} from "./Providers/LocationProvider";
import useWebSocket from "react-use-websocket";
import {WebSocketProvider} from "./Providers/WebsocketProvider";
import {getLocalAccessToken} from "./helpers/api_helper";
import {Container} from "reactstrap";
import Debug from "./pages/Debug/Debug";
import {MapElementsProvider} from "./Providers/MapElementsProvider";
import * as Sentry from "@sentry/react";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import AuthProtected from "./Routes/AuthProtected";
import i18n from "./i18n";
import {useTranslation} from "react-i18next";
import {UserInfo} from "./Types/atoms";
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_APIKEY,
//   authDomain: import.meta.env.VITE_AUTHDOMAIN,
//   databaseURL: import.meta.env.VITE_DATABASEURL,
//   projectId: import.meta.env.VITE_PROJECTID,
//   storageBucket: import.meta.env.VITE_STORAGEBUCKET,
//   messagingSenderId: import.meta.env.VITE_MESSAGINGSENDERID,
//   appId: import.meta.env.VITE_APPID,
//   measurementId: import.meta.env.VITE_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

// Activating fake backend
// fakeBackend();
document.title = navBarTitle;

if (!DisableVConsole) {
    const vConsole = new VConsole();
}
if (SENTRY_DNS !== undefined) {
    console.log("Sentry enabled", SENTRY_DNS)
    const feedback = Sentry.feedbackIntegration(
        {
            showName: false,
            showEmail: false,
            showBranding: false,
            colorScheme: "system",
            useSentryUser: {
                name: "fullName",
                email: "email",
            },
            autoInject: true,
        })

    Sentry.init({
        environment: import.meta.env.NODE_ENV,
        dsn: SENTRY_DNS,

        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            feedback,
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^\//],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
const App = () => {
    const Layout = VerticalLayout;


    const {t, i18n} = useTranslation();

    const [cssLoaded, setCssLoaded] = useState<boolean>(false)
    useEffect(() => {
        console.log(`loading ${BRAND_NAME}`)
        if (BRAND_NAME === BRAND_NAMES.QNTROL) {
            import("./App-qntrol.scss").then(() => {
                console.log(`loaded ${BRAND_NAME}`)
                setCssLoaded(true)
            })
                .catch((err) => {
                    console.error("Failed to load the theme", err);
                });

        }
        if (BRAND_NAME === BRAND_NAMES.SARDRONE) {
            import("./App-sardrone.scss").then(() => {
                console.log(`loaded ${BRAND_NAME}`)
                setCssLoaded(true)
            })
                .catch((err) => {
                    console.error("Failed to load the theme", err);
                });
        }
    }, []);
    const content = (<RecoilRoot>
            <ToastContainer
                position="top-center"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                limit={5}
            />

            <Routes>
                {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            key={idx}
                            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                        />
                    )
                )}
                {
                    authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            key={idx}
                            element={
                                <React.Fragment>
                                    <AuthProtected>
                                        <WebSocketProvider>
                                            <LocationProvider>

                                                <MapElementsProvider>
                                                    <Layout>
                                                        <ErrorBoundary
                                                            fallback={<p>Something went wrong</p>}
                                                        >
                                                            {route.component}
                                                        </ErrorBoundary>
                                                        {import.meta.env.NODE_ENV === "development" && (
                                                            <Debug></Debug>
                                                        )}
                                                    </Layout>
                                                </MapElementsProvider>
                                            </LocationProvider>
                                        </WebSocketProvider>
                                    </AuthProtected>
                                </React.Fragment>
                            }
                        />
                    ))
                }
            </Routes>
        </RecoilRoot>
    )
    return (

        <React.Fragment>
            {!cssLoaded && t("loading")}
            {cssLoaded && (content)}


        </React.Fragment>


    );

}

export default App;
