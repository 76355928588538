import {createAsyncThunk} from "@reduxjs/toolkit";

import {
    getMonthlyData as getMonthlyDataApi,
    getTranscation as getTranscationApi,
    getWeeklyData as getWeeklyDataApi,
    getYearlyData as getYearlyDataApi,
} from "../../helpers/fakebackend_helper";


export const getTranscation = createAsyncThunk("dashboard/getTranscation", async () => {
    try {
        const response = getTranscationApi();
        return response;
    } catch (error) {
        return error;
    }
});


export const getChartData = createAsyncThunk("dashboard/getChartData", async (data: any) => {
    try {
        var response: any;
        if (data === "weekly") {
            response = getWeeklyDataApi(data);
        }
        if (data === "monthly") {
            response = getMonthlyDataApi(data);
        }
        if (data === "yearly") {
            response = getYearlyDataApi(data);
        }
        return response;

    } catch (error) {
        return error;
    }
});

