import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { IMedia } from "../../Types/Media";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lightbox, { Slide } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

interface FileListProps {
  mediaData: IMedia[];
}

const FileList: React.FC<FileListProps> = ({ mediaData }) => {
  dayjs.extend(relativeTime);
  const [photoIndex, setphotoIndex] = useState<number>(0);
  const [isGallery, setisGallery] = useState<boolean>(false);
  const [lightboxData, setlightboxData] = useState<any>([]);

  useEffect(() => {
    console.log(mediaData);
    // setlightboxData([{
    //     type: "video" as const,
    //     width: 1280,
    //     height: 720,
    //     poster:
    //         "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
    //     sources: [
    //         {
    //             src:
    //                 "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    //             type: "video/mp4"
    //         }
    //     ]
    // }])
    const data: any[] = [];
    mediaData.map((myfiles, key) => {
      const obj = {
        title: myfiles.name,
        type: myfiles.media_type,
        poster: myfiles.thumbnail,
        description: `Created: ${myfiles.metadata?.created_time} Altitude: ${myfiles.metadata?.absolute_altitude}m Dron Location: ${myfiles.metadata?.shoot_position.coordinates[1]},${myfiles.metadata?.shoot_position.coordinates[0]} Photo Location: ${myfiles.metadata?.photoed_position.coordinates[1]},${myfiles.metadata?.photoed_position.coordinates[0]}`,
      };
      if (myfiles.media_type === "video") {
        // obj["poster"]=myfiles.thumbnail
        // obj["sources"]={
        //     src: myfiles.object_url,
        //     type: "video/mp4"
        // }
        obj["width"] = 1280;
        obj["height"] = 720;
        obj["poster"] = myfiles.thumbnail
          ? myfiles.thumbnail
          : "/assets/video-placeholder.jpg";
        obj["sources"] = [
          {
            src: myfiles.object_url,
            type: "video/mp4",
          },
        ];
      } else {
        obj["src"] = myfiles.object_url;
      }
      data.push(obj);
    });
    setlightboxData(data);
  }, [mediaData]);
  useEffect(() => {
    console.log(lightboxData);
  }, [lightboxData]);
  return (
    <React.Fragment>
      {isGallery ? (
        <Lightbox
          close={() => setisGallery(false)}
          open={isGallery}
          plugins={[Captions, Fullscreen, Video, Zoom, Thumbnails, Slideshow]}
          index={photoIndex}
          slides={lightboxData}
        />
      ) : null}
      <div>
        {/*<Row className="mb-3">*/}
        {/*  <Col xl={3} sm={6}>*/}
        {/*    <div className="mt-2">*/}
        {/*      <h5>My Files</h5>*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*  <Col xl={9} sm={6}>*/}
        {/*    <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">*/}
        {/*      <div className="search-box mb-2 me-2">*/}
        {/*        <div className="position-relative">*/}
        {/*          <input*/}
        {/*            type="text"*/}
        {/*            className="form-control bg-light border-light rounded"*/}
        {/*            placeholder="Search..."*/}
        {/*          />*/}
        {/*          <i className="bx bx-search-alt search-icon"></i>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*      <UncontrolledDropdown className="mb-0">*/}
        {/*        <DropdownToggle*/}
        {/*          className="btn btn-link text-muted mt-n2"*/}
        {/*          tag="a"*/}
        {/*        >*/}
        {/*          <i className="mdi mdi-dots-vertical font-size-20"></i>*/}
        {/*        </DropdownToggle>*/}

        {/*        <DropdownMenu className="dropdown-menu-end">*/}
        {/*          <DropdownItem href="#">*/}
        {/*            Share Files*/}
        {/*          </DropdownItem>*/}
        {/*          <DropdownItem href="#">*/}
        {/*            Share with me*/}
        {/*          </DropdownItem>*/}
        {/*          <DropdownItem href="#">*/}
        {/*            Other Actions*/}
        {/*          </DropdownItem>*/}
        {/*        </DropdownMenu>*/}
        {/*      </UncontrolledDropdown>*/}
        {/*    </Form>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </div>
      <div>
        <Row>
          {mediaData.map((myfiles, key) => (
            <Col xl={3} sm={6} key={key}>
              <Card className="shadow-none border">
                <CardBody className="p-3">
                  <div>
                    <div className="float-end ms-2">
                      <UncontrolledDropdown className="mb-2">
                        <DropdownToggle
                          className="font-size-16 text-muted"
                          tag="a"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Open</DropdownItem>
                          <DropdownItem href="#">Edit</DropdownItem>
                          <DropdownItem href="#">Rename</DropdownItem>
                          <div className="dropdown-divider"></div>
                          <DropdownItem href="#">Remove</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className="mt-4 mt-md-0">
                      <LazyLoadImage
                        className="rounded avatar-xl"
                        style={{ cursor: "pointer" }}
                        src={
                          myfiles.thumbnail
                            ? myfiles.thumbnail
                            : "/assets/video-placeholder.jpg"
                        }
                        onClick={() => {
                          setisGallery(true);
                          setphotoIndex(key);
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="d-flex">
                      <div className="overflow-hidden me-auto">
                        <h5 className="font-size-14 text-truncate mb-1">
                          <Link
                            to="#"
                            className="text-body"
                            onClick={() => {
                              setisGallery(true);
                              setphotoIndex(key);
                            }}
                          >
                            {myfiles.name}
                          </Link>
                        </h5>
                        <p className="text-muted text-truncate mb-0">
                          {dayjs(myfiles.metadata?.created_time)?.fromNow() ??
                            " -- "}
                        </p>
                        <p className="text-muted text-truncate mb-0">
                          Altitude: {myfiles.metadata?.absolute_altitude}m
                        </p>
                        <p className="text-muted text-truncate mb-0">
                          Elevation: {myfiles.metadata?.relative_altitude}m
                        </p>
                        <p className="text-muted text-truncate mb-0">
                          Dron Location:
                          <a
                            target={"_blank"}
                            href={`https://www.google.com/maps/place/${myfiles.metadata?.shoot_position.coordinates[1]},${myfiles.metadata?.shoot_position.coordinates[0]}`}
                          >
                            {myfiles.metadata?.shoot_position.coordinates[1]},{" "}
                            {myfiles.metadata?.shoot_position.coordinates[0]}
                          </a>
                        </p>
                        {myfiles.metadata?.photoed_position.coordinates[1] !==
                        0 ? (
                          <p className="text-muted text-truncate mb-0">
                            Photo Location:
                            {myfiles.metadata?.photoed_position.coordinates[1]},
                            {myfiles.metadata?.photoed_position.coordinates[0]}
                          </p>
                        ) : null}
                      </div>
                      <div className="align-self-end ms-2">
                        <p className="text-muted mb-0">ID: {myfiles.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default FileList;
