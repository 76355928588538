import React from "react";
import { IStream } from "../../Types/Common";
import { Badge } from "reactstrap";
import PageErrorBoundary from "../PageErrorBoundary/PageErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import isEqual from "react-fast-compare";

const StreamBadge: React.FC<IStream> = ({ ...stream }: IStream) => {
  return (
    <ErrorBoundary FallbackComponent={PageErrorBoundary}>
      <h5>
        <div className={"d-flex gap-2 flex-wrap"}>
          {stream.is_live ? (
            <Badge pill color="success">
              Online
            </Badge>
          ) : (
            <Badge pill color="danger">
              Offline
            </Badge>
          )}

          {stream.recordings_count > 0 && (
            <Link to={`/video/${stream.id}`}>
              <i className="bx bxs-videos"></i>
            </Link>
          )}
        </div>
      </h5>
    </ErrorBoundary>
  );
};
export default React.memo(StreamBadge, isEqual);
