import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IDrone, IRc } from "Types/Dron";
import EasyEdit from "react-easy-edit";
import { updateDeviceApi } from "../helpers/sarlink_backed";
import { toast } from "react-toastify";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";

const EditDroneName: React.FC<IDrone | IRc> = (droneData) => {
    const {t, i18n} = useTranslation();

    const save = (id: number, value: any) => {
    console.log(value);
    updateDeviceApi(id, { name: value })
      .then((res: any) => {
        console.log(res);
        toast.success(t("Name updated"));
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(t("Error updating name"));
      });
  };
  const cancel = () => {
    console.log("cancel");
  };
  return (
    <>
      <UncontrolledTooltip placement="top" target={`editName-${droneData.id}`}>
          {t("Edit name")}
      </UncontrolledTooltip>
      <div id={`editName-${droneData.id}`}>
        <EasyEdit
          type="text"
          onSave={(value: any) => save(droneData.id, value)}
          onCancel={cancel}
          // saveButtonLabel="Save"
          //cancelButtonLabel="Cancel"
          disableAutoSubmit={false}
          disableAutoCancel={false}
          // attributes={{ name: "awesome-input", id: 1}}
          // instructions="Star this repo!"
          saveButtonLabel={<FaCheck />}
          cancelButtonLabel={<FaTimes />}
          value={droneData.full_name}
        />
      </div>
    </>
  );
};
export default React.memo(EditDroneName, isEqual);
