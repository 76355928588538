import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { IRecordings, IStream } from "../../Types/Common";
import { StreamRecoringsListApi } from "../../helpers/sarlink_backed";
import { IDashboardData } from "../../Types/Dashboard";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSharedWebSocket } from "../../Providers/WebsocketProvider";
import { EWsMsgType, IWsDroneEvent, WsMessage } from "../../Types/Ws";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Lightbox, { Slide } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Spinners from "../../Components/Common/Spinner";
import isEqual from "react-fast-compare";

interface RecordingsProps {
  streamId: string | undefined;
}

const Recordings = (props: RecordingsProps) => {
  dayjs.extend(relativeTime);
  const [recordings, setRecordings] = useState<IRecordings[]>([]);
  const { sendMessage, lastMessage, readyState } = useSharedWebSocket();
  const [photoIndex, setphotoIndex] = useState<number>(0);
  const [isGallery, setisGallery] = useState<boolean>(false);
  const [lightboxData, setlightboxData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRecordings = async () => {
    console.log(props);
    setIsLoading(true);

    if (props.streamId === undefined) return;
    StreamRecoringsListApi(props.streamId)
      .then((response: IRecordings[]) => {
        setRecordings(response);
        const data: any[] = [];
        response.map((myfiles, key) => {
          const obj = {
            title: myfiles.created,
            type: "video",
            poster: myfiles.thumbnail,
            description: myfiles.created,
          };
          obj["width"] = 1280;
          obj["height"] = 720;
          obj["poster"] = myfiles.thumbnail;
          obj["sources"] = [
            {
              src: myfiles.video,
              type: "video/mp4",
            },
          ];
          data.push(obj);
        });
        setlightboxData(data);
        //console.log(data)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useLayoutEffect(() => {
    if (lastMessage === null) return;
    const data: WsMessage = JSON.parse(lastMessage?.data);

    if (data.msg_type === EWsMsgType.STREAM_CHANGED) {
      console.log(data.msg_type, data);
      getRecordings();
    }
  }, [lastMessage]);

  useEffect(() => {
    console.log("useEffect recordings");

    getRecordings();
    const intervalCall = setInterval(() => {
      getRecordings();
    }, 15000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [props.streamId]);
  return (
    <React.Fragment>
      {isGallery ? (
        <Lightbox
          close={() => setisGallery(false)}
          open={isGallery}
          index={photoIndex}
          slides={lightboxData}
          plugins={[Captions, Fullscreen, Video, Zoom, Thumbnails, Slideshow]}
        />
      ) : null}
      <Card className="filemanager-sidebar">
        <CardBody>
          {/*<div className="text-center">*/}
          {/*  <h5 className="font-size-15 mb-4">Storage</h5>*/}
          {/*  <div>*/}
          {/*    <ReactApexChart*/}
          {/*      options={options}*/}
          {/*      series={series}*/}
          {/*      type="radialBar"*/}
          {/*      height={150}*/}
          {/*      className="apex-charts"*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <p className="text-muted mt-4">48.02 GB (76%) of 64 GB used</p>*/}
          {/*</div>*/}
          {isLoading && <Spinners setLoading={setIsLoading} />}

          {recordings.length === 0 && isLoading ? (
            <Alert color="info">No recordings</Alert>
          ) : (
            <div className="card border shadow-none mb-2">
              <h5>Stream Recordings</h5>
              <div className="mt-4 overflow-scroll max-vh-100">
                {recordings.map((recording, index) => {
                  return (
                    <div className="p-2" key={`recording-${recording.id}`}>
                      <div className="d-flex">
                        <div className="avatar-xs align-self-center me-2">
                          <div className="avatar-title rounded bg-transparent text-success">
                            {/*{isOpenPhoto===recording.id.toString() ? (*/}
                            {/*    <Lightbox*/}
                            {/*        mainSrc={recording.thumbnail}*/}
                            {/*        enableZoom={false}*/}
                            {/*        imageCaption={*/}
                            {/*            "Caption. Can be aligned it to any side and contain any HTML."*/}
                            {/*        }*/}
                            {/*        onCloseRequest={() => {*/}
                            {/*            setisOpenPhoto(recording.id.toString());*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*) : null}*/}

                            <LazyLoadImage
                              onClick={() => {
                                setisGallery(true);
                                setphotoIndex(index);
                              }}
                              className="img-fluid"
                              alt="Skote"
                              src={recording.thumbnail}
                              width="145"
                            />
                          </div>
                        </div>

                        <div className="overflow-hidden me-auto">
                          <h5 className="font-size-13 text-truncate mb-1">
                            <a
                              href="#"
                              onClick={() => {
                                setisGallery(true);
                                setphotoIndex(index);
                                console.log(lightboxData[index]);
                              }}
                              target={""}
                            >
                              {dayjs(
                                recording?.recording_started_at
                              )?.fromNow() ?? " -- "}
                              <br />
                              {dayjs(
                                recording?.recording_started_at
                              )?.fromNow() ?? " -- "}
                            </a>
                          </h5>
                          <p className="text-muted text-truncate mb-0">
                            {recording.width}x{recording.height} @{" "}
                            {recording.fps} {recording.duration}s{" "}
                          </p>
                        </div>

                        {/*<div className="ml-2">*/}
                        {/*  <p className="text-muted">6 GB</p>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(Recordings, isEqual);
