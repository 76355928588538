import React, { useEffect, useState } from "react";
import withRouter from "../../Components/Common/withRouter";
import { Navigate, useNavigate } from "react-router-dom";

import { Button, Modal, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { LogoutApi } from "../../helpers/sarlink_backed";
import {
  clearLocalTokens,
  getLocalRefreshToken,
} from "../../helpers/api_helper";

const Logout = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toggle_modal = () => {
    setModal(!modal);
  };
  const handleCancel = () => {
    setModal(false);
    navigate(-1);
  };
  const handleLogout = () => {
    setIsLoading(true);
    const refresh_token = getLocalRefreshToken();
    if (!refresh_token) {
      clearLocalTokens();
      navigate("/login");
      return;
    }
    (async () => {
      //console.log(refresh_token)

      try {
        LogoutApi({ refresh_token: refresh_token }).then((response) => {
          clearLocalTokens();
          navigate("/login");
          return;
        });

        // Assuming data structure from response is not used, thus not typed
      } catch (e) {
        toast.error("An unexpected error occurred.");
        console.error(e);
        //console.log('logout not working', e);
      }
    })();
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle_modal();
      }}
    >
      <ModalHeader>Proceed to logout?</ModalHeader>
      <div className="modal-body">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: "4px" }}
          variant="danger"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </Modal>
  );
};

export default withRouter(Logout);
