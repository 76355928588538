import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSharedWebSocket } from "../../Providers/WebsocketProvider";
import { EWsMsgType, WsMessage, WsMessageUserLocation } from "../../Types/Ws";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecoilValue } from "recoil";
import { VisitorId } from "../../Types/atoms";

const NotificationDropdown = (props: any) => {
  dayjs.extend(relativeTime);
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState<WsMessageUserLocation[]>([]);
  const { sendMessage, lastMessage, readyState } = useSharedWebSocket();
  const visitorId = useRecoilValue(VisitorId);

  const removeOldItems = () => {
    const tenMinutes = 60 * 60;
    const threshold = Date.now() / 1000 - tenMinutes;
    console.log("removeOldItems", threshold);
    setOnlineUsers((currentItems) =>
      currentItems.filter((item) => item.timestamp >= threshold)
    );
  };

  const updateOrAddItem = (itemToUpdate: WsMessageUserLocation) => {
    const currentTime = new Date().getTime(); // Current time in milliseconds
    setOnlineUsers((prevItems) => {
      const itemIndex = prevItems.findIndex(
        (item) => item.browser_id === itemToUpdate.browser_id
      );

      if (itemIndex > -1) {
        // Update the existing item
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = itemToUpdate;
        return updatedItems;
      } else {
        // Add the new item
        return [...prevItems, itemToUpdate];
      }
    });
    //removeOldItems();
  };
  useEffect(() => {
    if (lastMessage === null) return;
    const data: WsMessage = JSON.parse(lastMessage?.data);
    //console.log("data", data)

    if (data.msg_type === EWsMsgType.USER_LOCATION) {
      //console.log(data.msg_type, data)
      updateOrAddItem(data.data as WsMessageUserLocation);
    }
  }, [lastMessage]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-user-pin" />
          <span className="badge bg-primary rounded-pill">
            {onlineUsers.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Online Users")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {onlineUsers.map((item) => (
              <div
                key={item.browser_id}
                className="text-reset notification-item"
              >
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span
                      className={`avatar-title bg-${
                        visitorId?.toString() === item.browser_id
                          ? "primary"
                          : "secondary"
                      } rounded-circle font-size-16`}
                    >
                      {item.user.initials}
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {item.user.email}{" "}
                      {visitorId?.toString() === item.browser_id ? "(ME)" : ""}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">ID: {item.browser_id}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {dayjs(item?.timestamp)?.fromNow() ?? " -- "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);
