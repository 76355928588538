//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";
export const GET_TRANSCECTION = "/transcection";

export const URL_LOGIN="/token/"
export const URL_KEY_LOGIN="/tokenkey/"
export const URL_LOGOUT="/logout/"
export const USER_INFO="/api/v1/userinfo/"
export const DRONEINFO="/api/v1/droneinfo/"
export const STREAM="/api/v1/stream/"
export const DEVICECAMERA="/api/v1/devicecamera/"

export const DEVICE="/api/v1/device/"
export const RTMP_INFO="/api/v1/rtmpinfo/"
export const PLATFORM_INFO="/api/v1/platforminfo/"
export const WORKSPACE="/api/v1/workspace/"
export const MAP_ELEMENTS="/api/v1/map/elements/"
export const MAP_ELEMENTS_GROUP="/api/v1/map/element-groups-workspace/"
export const GET_DEMO_DATA = "https://jsonplaceholder.typicode.com/post1s";

export const DRONE = "/api/v1/drone/"
export const MEDIA = "/api/v1/media/"
export const SWITCH_COMPANY = "/api/v1/switchcompany/"
export const RC = "/api/v1/rc/"